import DashboardQuiz from "../../components/DashboardQuiz/DashboardQuiz";

function Quiz() {
  return (
    <>
      <DashboardQuiz />
    </>
  );
}

export default Quiz;
