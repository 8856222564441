import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";

const ProtectedRoutes = () => {
  const { isLoggedIn } = useAuthContext();
  const location = useLocation();
  console.log(location.pathname);

  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate
      to="/signup"
      state={{
        from: location.pathname,
      }}
    />
  );
};

export default ProtectedRoutes;
