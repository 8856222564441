import "./Gallery.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import DashboardGallery from "../../components/dashboardGallery/DashboardGallery";

function Gallery() {
  return (
    <div className="gallery-page">
      <Sidebar />
      <DashboardGallery />
    </div>
  );
}

export default Gallery;
