import "./QuizProgressBar.scss";

function QuizProgressBar({ count, active }) {
  return (
    <div className="progress-wrapper">
      {Array.from(
        {
          length: count,
        },
        (_, index) => (
          <div
            className={`progress-wrapper__bar ${
              index <= active ? "progress-wrapper__bar--selected" : ""
            }`}
            key={index}
          ></div>
        )
      )}
    </div>
  );
}

export default QuizProgressBar;
