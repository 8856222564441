import "./MdpButtonCard.scss";

function MdpButtonCard({ title, details, isSelected, onSelect }) {
  const cardClass = isSelected ? "mdp-card__selected" : "mdp-card__unselected";

  return (
    <div className={cardClass} onClick={onSelect}>
      <p className="details">{details}</p>
      <p className="title">{title}</p>
    </div>
  );
}

export default MdpButtonCard;
