import { useState, useEffect } from "react";
import "./editQuizResponse.scss";
import tick from "../../assets/images/tickImg.svg";
import cash from "../../assets/icons/cash.svg";

export const OptionBlock = ({
  index,
  optionIndex,
  optionInfo,
  isFreezed,
  selectOption,
  unselectOption,
}) => {
  const iconSrcBlur = optionInfo.greyicon
    ? require(`../../assets/icons/${optionInfo.greyicon}`)
    : "";
  const [included, setIncluded] = useState(optionInfo.selected);

  function onHandleSelect() {
    if (isFreezed) return;
    if (!included) {
      selectOption(index, optionIndex);
    } else {
      unselectOption(index, optionIndex);
    }
    setIncluded(!included);
  }

  useEffect(() => {
    setIncluded(optionInfo.selected);
  }, [optionInfo.selected]);

  return (
    <>
      <div
        className={`option-block ${included ? "selected" : ""} ${
          optionInfo.text === "Other" ? "pad-space-2" : ""
        } ${!isFreezed ? "editable" : ""}`}
        onClick={onHandleSelect}
      >
        {iconSrcBlur && (
          <span className="option-block__greyscalecontainer">
            <img
              className="option-block__greyscalecontainer--greyscaleimg"
              src={iconSrcBlur}
            />
          </span>
        )}
        {!iconSrcBlur && optionInfo.text !== "Other" && (
          <span className="option-block__greyscalecontainer">
            <img
              className="option-block__greyscalecontainer--greyscaleimg"
              src={cash}
            />
          </span>
        )}
        <div className="option-block__container">
          <div
            className={`option-block__text ${
              optionInfo.subtext.length > 0 ? "pad-space" : ""
            }`}
          >
            <span className="option-block__text--main">{optionInfo.text}</span>
            <span className="option-block__text--sub">
              {optionInfo.subtext}
            </span>
          </div>
          {included ? <img className="selected-img" src={tick} /> : null}
        </div>
      </div>
    </>
  );
};

export const FeatureBlock = ({
  index,
  optionIndex,
  optionInfo,
  isFreezed,
  selectOption,
  unselectOption,
}) => {
  const iconSrcBlur = optionInfo.greyicon
    ? require(`../../assets/icons/${optionInfo.greyicon}`)
    : "";
  const [included, setIncluded] = useState(optionInfo.selected);

  function onHandleSelect() {
    if (isFreezed) return;
    if (!included) {
      selectOption(index, optionIndex);
    } else {
      unselectOption(index, optionIndex);
    }
    setIncluded(!included);
  }
  useEffect(() => {
    setIncluded(optionInfo.selected);
  }, [optionInfo.selected]);

  return (
    <>
      <div
        className={`feature-block ${included ? "f-selected" : ""}  ${
          !isFreezed ? "editable" : ""
        }`}
        onClick={onHandleSelect}
      >
        {iconSrcBlur ? (
          <span className="feature-block__greyscalecontainer">
            <img
              className="feature-block__greyscalecontainer--greyscaleimg"
              src={iconSrcBlur}
            />
          </span>
        ) : null}
        <div className="feature-block__container">
          <div className="feature-block__textbox">
            <span className="feature-block__text">{optionInfo.text}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export const EditQuizResponse = ({
  index,
  desc,
  options,
  isFreezed,
  selectOption,
  unselectOption,
  currSelected,
}) => {
  return (
    <>
      <div className="quiz-box">
        <div className="quiz-box__textbox">
          <h2 className="quiz-box__header">{desc} </h2>
          {desc === "Vehicle Engine" ? (
            <h3 className="quiz-box__subheader">
              Select all your preference(s)
            </h3>
          ) : null}
        </div>
        <div
          className={`${
            desc !== "Feature Request"
              ? "quiz-box__option-container"
              : "quiz-box__feature-container"
          }`}
        >
          {options.map((option, optionIndex) => {
            return desc !== "Feature Request" ? (
              <OptionBlock
                key={optionIndex}
                index={index}
                optionIndex={optionIndex}
                optionInfo={option}
                isFreezed={isFreezed}
                selectOption={selectOption}
                unselectOption={unselectOption}
              />
            ) : (
              <FeatureBlock
                key={optionIndex}
                index={index}
                optionIndex={optionIndex}
                optionInfo={option}
                isFreezed={isFreezed}
                selectOption={selectOption}
                unselectOption={unselectOption}
              />
            );
          })}
        </div>
        <span className="quiz-box__enderline"></span>
      </div>
    </>
  );
};
