import Dashboard404 from "../../components/dashboard404/Dashboard404";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuthContext } from "../../contexts/authContext";

function NotFound() {
  const { isLoggedIn } = useAuthContext();

  return (
    <>
      {isLoggedIn && (
        <Sidebar
        // isLoggedIn={isLoggedIn}
        // toggleLogin={toggleLogin}
        // setUser={setUser}
        // user={user}
        />
      )}
      <Dashboard404 />
    </>
  );
}

export default NotFound;
