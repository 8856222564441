import "./DashboardQuiz.scss";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { questions } from "../../data/quiz";
import { useAuthContext } from "../../contexts/authContext";
import QuizProgressBar from "../QuizProgressBar/QuizProgressBar";
import QuizOptionCard from "../quizOptionCard/QuizOptionCard";
import ButtonPlain from "../buttonPlain/ButtonPlain";
import badgeLogo from "../../assets/logos/mae-badge-logo.svg";
import LoadingScreen from "../loadingScreen/loadingScreen";
import { updateAnonymousUserService } from "../../services/userEvent";
import { generateProfileService } from "../../services/profile";
import { generateAnonymousProfileService } from "../../services/profile";
import {
  ANONYMOUS_PROFILE_GENERATED,
  PROFILE_GENERATED,
} from "../../constants/lastActions";

function DashboardQuiz() {
  const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
  const [quizQuestions, setquizQuestions] = useState(
    JSON.parse(JSON.stringify(questions))
  );
  const [isLoading, setIsLoading] = useState(false);
  const { setMatchmaker, isLoggedIn, setLastAction } = useAuthContext();

  const navigate = useNavigate();
  const count = questions.length;

  const quizComponentRef = useRef();

  useEffect(() => {
    quizComponentRef.current.scrollTop = 0;
  }, [currentQuestionIndex]);

  //CQ: seperate the quiz helper functions in a quizHelpers.js in this folder

  const countSelected = () => {
    let count = 0;
    quizQuestions[currentQuestionIndex].options.forEach((option) => {
      if (option.selected) {
        count++;
      }
    });
    return count;
  };

  const selectOption = (index) => {
    const stateCopy = [...quizQuestions];
    const currentQuestion = stateCopy[currentQuestionIndex];
    const selectedOptions = currentQuestion.currSelected;
    const maxOptionLen = currentQuestion.selectionRange[1];
    if (selectedOptions.length >= maxOptionLen) {
      const removedOptionInfo = selectedOptions.shift();
      currentQuestion.options[removedOptionInfo].selected = false;
    }
    if (
      !selectedOptions.includes(index) &&
      selectedOptions.length < maxOptionLen
    ) {
      selectedOptions.push(index);
      currentQuestion.options[index].selected = true;
    }
    setquizQuestions(stateCopy);
  };

  const unselectOption = (index) => {
    const stateCopy = [...quizQuestions];
    const currentQuestion = stateCopy[currentQuestionIndex];
    const selectedOptions = currentQuestion.currSelected;
    const selectedOptionsIndex = selectedOptions.indexOf(index);
    if (selectedOptions.includes(index)) {
      currentQuestion.options[index].selected = false;
      selectedOptions.splice(selectedOptionsIndex, 1);
    }
    setquizQuestions(stateCopy);
  };

  const nextQuestion = () => {
    console.log("next question");
    if (currentQuestionIndex + 1 < count) {
      setcurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      //completed quiz
      navigate("/speed-dating");
    }
  };

  const prevQuestion = () => {
    console.log("prev question");
    if (currentQuestionIndex - 1 >= 0) {
      setcurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      navigate("/");
    }
  };

  const sendBeaconQuizResponses = () => {
    const quizResponses = [];
    quizQuestions.forEach((item) => {
      const selectedOptions = item.options
        .filter((option) => option.selected)
        .map((option) => option.text);
      if (selectedOptions.length > 0) {
        quizResponses.push(selectedOptions.join(","));
      }
    });

    updateAnonymousUserService({
      quizResponses,
      numberOfQuestionsAnswered: quizResponses.length,
    });
  };

  const submit = async () => {
    setIsLoading(true);

    sendBeaconQuizResponses();

    //CQ: create a function make the quiz selections object -> quizHelpers.js
    const quizSelections = [];

    for (let i = 0; i < count; i++) {
      var selectedOptions = [];
      const question = quizQuestions[i];

      if (
        question.text === "Lastly, what features would you like in your car?"
      ) {
        const featureOptions = {};

        question.options.forEach((option) => {
          if (option.field) {
            featureOptions[option.field] = option.selected;
          }
        });

        quizSelections.push({
          question: question.text,
          selectedOptions: [featureOptions],
        });
      } else {
        // eslint-disable-next-line no-loop-func
        question.options.forEach((option) => {
          if (option.selected) {
            selectedOptions.push(option.text);
          }
        });
        quizSelections.push({
          question: question.text,
          selectedOptions,
        });
      }
    }
    console.log(quizSelections, "quizSelections");

    let profile_response = null;
    profile_response = isLoggedIn
      ? await generateProfileService(quizSelections)
      : await generateAnonymousProfileService(quizSelections);
    if (profile_response == null) {
      navigate("/404");
      return;
    }
    setMatchmaker(profile_response.matchmaker);
    isLoggedIn
      ? setLastAction(PROFILE_GENERATED)
      : setLastAction(ANONYMOUS_PROFILE_GENERATED)
    navigate("/speed-dating");
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sendBeaconQuizResponses();
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="quiz" ref={quizComponentRef}>
      <div className="quiz__filler">
        <div className="quiz__intro">
          <div className="quiz__intro--titles">
            <h2 className="header">
              {quizQuestions[currentQuestionIndex].header}
            </h2>
            <h1 className="subheader">
              {quizQuestions[currentQuestionIndex].text}
            </h1>
            <h3 className="subtext">
              {quizQuestions[currentQuestionIndex].subtext}
            </h3>
            <QuizProgressBar count={count} active={currentQuestionIndex} />
          </div>
          <div className="badge">
            <img
              className="badge__logo"
              src={badgeLogo}
              alt=""
              style={{ height: "100px" }}
            />
          </div>
        </div>
        <div className="quiz__options">
          {quizQuestions[currentQuestionIndex].options.map((option, index) => {
            if (
              quizQuestions[currentQuestionIndex].shortDescription ===
                "Vehicle Engine" &&
              option.text === "Full electric" &&
              quizQuestions[2].options[4].selected
            ) {
              return null;
            }
            return (
              <QuizOptionCard
                key={currentQuestionIndex * 100 + index}
                index={index}
                text={option.text}
                subtext={option.subtext}
                greyicon={option.greyicon}
                selectOption={selectOption}
                unselectOption={unselectOption}
                selected={option.selected}
                className={
                  quizQuestions[currentQuestionIndex].text ===
                  "Lastly, what features would you like in your car?"
                    ? "feature-card"
                    : ""
                }
              />
            );
          })}
        </div>
        <div className="quiz__buttons">
          <div>
            <ButtonPlain
              border="none"
              padding="1.25rem 2.5rem"
              text="Back"
              onClick={prevQuestion}
            />
          </div>
          <div>
            {currentQuestionIndex + 1 < count ? (
              <ButtonPlain
                color={
                  countSelected() >=
                  quizQuestions[currentQuestionIndex].selectionRange[0]
                    ? "#FFE6A9"
                    : "#EEEEED"
                }
                border="none"
                padding="1.25rem 4.125rem"
                text="Continue"
                onClick={
                  countSelected() >=
                  quizQuestions[currentQuestionIndex].selectionRange[0]
                    ? nextQuestion
                    : null
                }
              />
            ) : (
              <ButtonPlain
                color={
                  countSelected() >=
                  quizQuestions[currentQuestionIndex].selectionRange[0]
                    ? "#FFE6A9"
                    : "#EEEEED"
                }
                border="none"
                padding="1.25rem 4.125rem"
                text="Continue"
                onClick={
                  countSelected() >=
                  quizQuestions[currentQuestionIndex].selectionRange[0]
                    ? submit
                    : null
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardQuiz;

// import "./DashboardQuiz.scss";
// // import ButtonGradient from "../buttonGradient/ButtonGradient";
// import QuizProgressBar from "../QuizProgressBar/QuizProgressBar";
// import QuizOptionCard from "../quizOptionCard/QuizOptionCard";
// import questions from "../../data/quiz.json";
// import { useEffect, useState, useContext } from "react";
// // import PlainButton from "../buttonPlain/ButtonPlain";
// import { useNavigate } from "react-router-dom";
// import ButtonPlain from "../buttonPlain/ButtonPlain";
// import { QuizContext } from "../../contexts/QuizContext";

// import QuizBudgetSlider from "../quizBudgetSlider/QuizBudgetSlider";
// import badgeLogo from "../../assets/logos/mae-badge-logo.svg";

// function DashboardQuiz() {
// 	const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
// 	const [budgetQuestionIndex, setBudgetQuestionIndex] = useState(7);
// 	// const [quizQuestions, setquizQuestions] = useState(questions);
// 	const [quizQuestions, setquizQuestions] = useState(questions);
// 	// const [selectedCount, setselectedCount] = useState(0);
// 	const { quizData, setQuizData, matchesData, setMatchesData } =
// 		useContext(QuizContext);
// 	const [selectedBudget, setSelectedBudget] = useState();

// 	const navigate = useNavigate();
// 	const count = questions.length;

// 	useEffect(() => {
// 		console.log(quizQuestions);
// 		console.log(
// 			currentQuestionIndex,
// 			quizQuestions[currentQuestionIndex],
// 			count,
// 			countSelected()
// 		);
// 	});

// 	// const handleBudgetRange = (selectedBudget, option, index) => {
// 	// 	console.log(
// 	// 		"Selected budget:",
// 	// 		currentQuestionIndex,
// 	// 		selectedBudget,
// 	// 		option,
// 	// 		index
// 	// 	);
// 	// 	setSelectedBudget(selectedBudget);
// 	// };

// 	// const handleSelectBudgetRange = (value) => {
// 	// 	setSelectedBudget(value);
// 	// 	console.log("Budget selected:", value);
// 	// 	console.log("selected budget!!!!!", currentQuestionIndex, {
// 	// 		text: `Budget: ${value}`,
// 	// 	});
// 	// };
// 	// const handleSelectBudgetRange = (
// 	// 	value,
// 	// 	quizQuestions,
// 	// 	currentQuestionIndex,
// 	// ) => {
// 	// 	setSelectedBudget(value);

// 	// 	// Find the corresponding option in the JSON for the selected budget range
// 	// 	const budgetQuestion = quizQuestions.find((q) => q.type === "budget");
// 	// 	const selectedOption = budgetQuestion.options.find(
// 	// 		(opt) => JSON.stringify(opt.value) === JSON.stringify(value)
// 	// 	);

// 	// 	// Log the selected option's text (or default to the range if not found)
// 	// 	console.log("selected", currentQuestionIndex, {
// 	// 		text: selectedOption ? selectedOption.text : `Budget: ${value}`,
// 	// 	});
// 	// };

// 	const handleSelectBudgetRange = (option, value) => {
// 		setSelectedBudget(value);

// 		if (!Array.isArray(questions)) {
// 			console.error("Questions is not an array:", questions);
// 		}

// 		// Find the budget question in quizQuestions and update its option's value
// 		const updatedQuestions = quizQuestions.map((question) => {
// 			if (question.text === "What’s your ideal budget?") {
// 				return {
// 					...question,
// 					options: [{ text: `Budget: ${value}`, selected: true }],
// 				};
// 			}
// 			return question;
// 		});
// 		setquizQuestions(updatedQuestions);
// 	};

// 	const countSelected = () => {
// 		var count = 0;
// 		quizQuestions[currentQuestionIndex].options.forEach((option) => {
// 			if (option.selected) {
// 				count += 1;
// 			}
// 		});
// 		return count;
// 	};

// 	const selectOption = (option, index) => {
// 		const stateCopy = { ...quizQuestions };
// 		stateCopy[currentQuestionIndex].options[index].selected = true;
// 		setquizQuestions(stateCopy);
// 		console.log("selected", currentQuestionIndex, option);
// 		console.log(stateCopy[currentQuestionIndex].options[index]);
// 	};

// 	const unselectOption = (option, index) => {
// 		const stateCopy = { ...quizQuestions };
// 		stateCopy[currentQuestionIndex].options[index].selected = false;
// 		setquizQuestions(stateCopy);
// 		// console.log("selected", currentQuestionIndex, option);
// 		// console.log(stateCopy[currentQuestionIndex].options[index]);
// 	};

// 	const nextQuestion = () => {
// 		// console.log("next question");
// 		if (currentQuestionIndex + 1 < count) {
// 			setcurrentQuestionIndex(currentQuestionIndex + 1);
// 		} else {
// 			//completed quiz
// 			navigate("/speed-dating");
// 		}
// 		// setcurrentQuestion(quizQuestions[currentQuestionIndex]);
// 	};

// 	const prevQuestion = () => {
// 		// console.log("prev question");
// 		if (currentQuestionIndex - 1 >= 0) {
// 			setcurrentQuestionIndex(currentQuestionIndex - 1);
// 		} else {
// 			//completed quiz
// 			setcurrentQuestionIndex(0);
// 		}
// 		// setcurrentQuestion(quizQuestions[currentQuestionIndex]);
// 	};

// 	const submit = async () => {
// 		const quizSelections = { selected: [] };

// 		for (let i = 0; i < count; i++) {
// 			const question = quizQuestions[i];

// 			if (
// 				question.text === "Lastly, what features would you like in your car?"
// 			) {
// 				const featureOptions = {};

// 				question.options.forEach((option) => {
// 					if (option.field) {
// 						featureOptions[option.field] = option.selected;
// 					}
// 				});

// 				quizSelections.selected.push({
// 					question: question.text,
// 					selectedOptions: featureOptions,
// 				});
// 			} else {
// 				const selectedOptions = [];

// 				question.options.forEach((option) => {
// 					if (option.selected) {
// 						if (question.text === "What's your ideal budget?") {
// 							selectedOptions.push(selectedBudget);
// 						} else {
// 							selectedOptions.push(option.text);
// 						}
// 					}
// 				});

// 				// const selectedOptions = [];

// 				// question.options.forEach((option) => {
// 				// 	if (option.selected) {
// 				// 		if (question.text === "What's your ideal budget?") {
// 				// 			selectedOptions.push(option.value);
// 				// 		} else {
// 				// 			selectedOptions.push(option.text);
// 				// 		}
// 				// 	}
// 				// });

// 				quizSelections.selected.push({
// 					question: question.text,
// 					selectedOptions: selectedOptions,
// 				});
// 			}
// 		}

// 		try {
// 			const response = await fetch("/generateProfile", {
// 				method: "POST",
// 				headers: {
// 					"Content-Type": "application/json",
// 				},
// 				credentials: "include",
// 				body: JSON.stringify(quizSelections),
// 			});

// 			// Handle the response
// 			if (response.ok) {
// 				let res = await response.json();
// 				let matchmaker = res.matchmaker;
// 				console.log("Number of cars matched:", matchmaker.carMatches.length);
// 				setMatchesData(matchmaker);
// 				navigate("/speed-dating");
// 			} else {
// 				// Login failed
// 				const errorData = await response.json();
// 				console.log("Failed:", errorData.error);
// 				// Display an error message to the user
// 			}
// 		} catch (error) {
// 			console.log("Error:", error.message);
// 		}
// 	};

// 	useEffect(() => {
// 		const cards = document.querySelectorAll(".card");
// 		const numberOfCards = cards.length;
// 		const cardWidth = 11.625; // width of a card
// 		const gap = 0.8; // gap

// 		const maxCardsPerRow = 4; // Max number of cards in a single row.

// 		let rows = Math.ceil(numberOfCards / maxCardsPerRow);
// 		let cardsInLastRow = numberOfCards % maxCardsPerRow || maxCardsPerRow;
// 		let totalWidth = (cardWidth + gap) * (numberOfCards - 1) + cardWidth;

// 		if (rows > 1) {
// 			totalWidth = (cardWidth + gap) * maxCardsPerRow;
// 		} else {
// 			totalWidth = (cardWidth + gap) * cardsInLastRow;
// 		}

// 		document.querySelector(".quiz__options").style.width = `${totalWidth}rem`;
// 	}, [quizQuestions]);

// 	return (
// 		<div className="quiz">
// 			<div className="quiz__filler">
// 				<div className="quiz__intro">
// 					<div className="quiz__intro--titles">
// 						<h2 className="header">
// 							{quizQuestions[currentQuestionIndex].header}
// 						</h2>
// 						<h1 className="subheader">
// 							{quizQuestions[currentQuestionIndex].text}
// 						</h1>
// 						<h3 className="subtext">
// 							{quizQuestions[currentQuestionIndex].subtext}
// 						</h3>
// 						<QuizProgressBar count={count} active={currentQuestionIndex} />
// 					</div>
// 					<div className="badge">
// 						<img className="badge__logo" src={badgeLogo} alt="" />
// 					</div>
// 				</div>
// 				{quizQuestions[currentQuestionIndex].text ===
// 					"What’s your ideal budget?" && (
// 					<h1 className="budget-title">MSRP Price Range</h1>
// 				)}
// 				<div className="quiz__options">
// 					{quizQuestions[currentQuestionIndex].text ===
// 					"What’s your ideal budget?" ? (
// 						<QuizBudgetSlider
// 							key={currentQuestionIndex}
// 							options={quizQuestions[currentQuestionIndex].options}
// 							selectBudgetRange={handleSelectBudgetRange}
// 						/>
// 					) : (
// 						quizQuestions[currentQuestionIndex].options.map(function (
// 							option,
// 							index
// 						) {
// 							return (
// 								<QuizOptionCard
// 									key={currentQuestionIndex * 100 + index}
// 									index={index}
// 									text={option.text}
// 									subtext={option.subtext}
// 									greyicon={option.greyicon}
// 									selectOption={selectOption}
// 									unselectOption={unselectOption}
// 									selected={option.selected}
// 									className={
// 										quizQuestions[currentQuestionIndex].text ===
// 										"Lastly, what features would you like in your car?"
// 											? "feature-card"
// 											: ""
// 									}
// 								/>
// 							);
// 						})
// 					)}
// 				</div>
// 				<div className="quiz__buttons">
// 					<div>
// 						<ButtonPlain
// 							// color="none"
// 							border="none"
// 							padding="1.25rem 2.5rem"
// 							text="Back"
// 							onClick={prevQuestion}
// 						/>
// 					</div>
// 					<div>
// 						{currentQuestionIndex + 1 < count ? (
// 							<ButtonPlain
// 								// style="bold"
// 								color={
// 									(currentQuestionIndex === budgetQuestionIndex &&
// 										selectedBudget) ||
// 									(currentQuestionIndex !== budgetQuestionIndex &&
// 										countSelected() > 0)
// 										? "#FFE6A9"
// 										: "#EEEEED"
// 								}
// 								border="none"
// 								padding="1.25rem 6.125rem"
// 								text={
// 									(currentQuestionIndex === budgetQuestionIndex &&
// 										selectedBudget) ||
// 									(currentQuestionIndex !== budgetQuestionIndex &&
// 										countSelected() > 0)
// 										? "Continue"
// 										: "Continue"
// 								}
// 								onClick={
// 									(currentQuestionIndex === budgetQuestionIndex &&
// 										selectedBudget) ||
// 									(currentQuestionIndex !== budgetQuestionIndex &&
// 										countSelected() > 0)
// 										? nextQuestion
// 										: null
// 								}
// 							/>
// 						) : (
// 							<ButtonPlain
// 								// style="bold"
// 								color="#EEEEED"
// 								border="none"
// 								padding="1.25rem 6.125rem"
// 								text={
// 									(currentQuestionIndex === budgetQuestionIndex &&
// 										selectedBudget) ||
// 									(currentQuestionIndex !== budgetQuestionIndex &&
// 										countSelected() > 0)
// 										? "Submit"
// 										: "Select Options"
// 								}
// 								onClick={
// 									(currentQuestionIndex === budgetQuestionIndex &&
// 										selectedBudget) ||
// 									(currentQuestionIndex !== budgetQuestionIndex &&
// 										countSelected() > 0)
// 										? submit
// 										: null
// 								}
// 							/>
// 						)}
// 					</div>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

// export default DashboardQuiz;
