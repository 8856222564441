import "./carCompatibilityInfo.scss";
import EllipsePercentMatch from "../ellipsePercentMatch/EllipsePercentMatch.jsx";

function CarCompatibilityInfo({ carInfo }) {
  return (

      <div className="compatibility-info">
        <div className="compatibility-info__circle">
          <EllipsePercentMatch percentage={carInfo.compatibilityPercentage} />
          <div className="compatibility-info-stamp">
            <p className="compatibility-info-stamp__number">
              {carInfo.compatibilityPercentage}
              <span className="compatibility-info-stamp__percent">%</span>
            </p>
            <p className="compatibility-info-stamp__text">match</p>
          </div>
        </div>
        <div className="compatibility-info-match">
          <div className="compatibility-info-match__row">
            <p className="compatibility-info-match__text-bold">8/8</p>
            <p className="compatibility-info-match__text-normal">requirements</p>
          </div>
          <div className="compatibility-info-match__row">
            <p className="compatibility-info-match__text-bold">
              {" "}
              {carInfo.matchedFeatures.length}/{carInfo.totalFeatures.length}
            </p>
            <p className="compatibility-info-match__text-normal">features</p>
          </div>
        </div>
      </div>
  
  );
}

export default CarCompatibilityInfo;

