import { useLocation } from "react-router-dom";

import "./DashboardAuthenticate.scss";
import dashboardSignInImg from "../../assets/images/login-icon.svg";
import dashboardSignUpImg from "../../assets/images/dashboard-authenticate.svg";

function DashboardAuthenticate() {
  const location = useLocation();

  // Determine the image based on the current path
  let currentImg;
  switch (location.pathname) {
    case "/signin":
      currentImg = dashboardSignInImg;
      break;
    case "/signup":
      currentImg = dashboardSignUpImg;
      break;
    default:
      currentImg = dashboardSignUpImg;
  }

  return (
    <>
      <div className="authenticate-filler">
        <div className="dashboard-authenticate">
          <img
            className="dashboard-authenticate__img"
            src={currentImg}
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default DashboardAuthenticate;
