export const likeMaeTrimService = (likedMaeTrimId, id) => {
  const data = JSON.stringify({ likedMaeTrimId });

  const beaconSent = navigator.sendBeacon(
    "/api/speeddating/likeMaeTrim",
    data,
    {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }
  );

  if (beaconSent) {
    console.log("Liked car beacon sent successfully");
  } else {
    console.error("Failed to send user event beacon");
  }
};

export const dislikeMaeTrimService = (dislikedMaeTrimId, id) => {
  const data = JSON.stringify({ dislikedMaeTrimId });

  const beaconSent = navigator.sendBeacon(
    "/api/speeddating/dislikeMaeTrim",
    data,
    {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }
  );

  if (beaconSent) {
    console.log("Disliked car beacon sent successfully");
  } else {
    console.error("Failed to send user event beacon");
  }
};
