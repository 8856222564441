import "./extraCarSection.scss";
import CarCard from "../carCard/carCard";

export default function ExtraCarSection({
  extraVehicles,
  setExtraVehicles,
  carSelection,
}) {
  function removeCarFromSelection(id) {
    const extraVehiclesFiltered = extraVehicles.filter((vehicle) => {
      return vehicle._maeTrimId !== id;
    });
    setExtraVehicles(extraVehiclesFiltered);
  }

  return (
    <div className={`extra-car__wrapper`}>
      {extraVehicles.map((vehicle, index) => (
        <CarCard 
        key = {index}
        carInfo={vehicle} 
        cardSelectionOnClick={carSelection} 
        isCardSelected={true} 
        isCardSelectable={true}  
        isCardLinkingToMDP={false} 
        showLikeDislike={false}
        isCompatibilityVisible={false}/>
      ))}
    </div>
  );
}
