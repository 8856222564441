import "./SelectCarStatus.scss";
import { ReactComponent as Bin } from "../../assets/icons/bin.svg";
import { ReactComponent as StraightBar } from "../../assets/icons/straight-bar.svg";

export default function SelectCarStatus({
  // remove,
  setCarsToCompareId,
  number,
  setExtraVehicles
  // selectedConstant,
}) {
  // function clearAndDeselect() {
  //   remove(false);
  //   setCarsToCompareId([]);
  // }

  function clearOnly() {
    setCarsToCompareId([]);
    setExtraVehicles([]);
  }
  return (
    <div className="selection-car-layout">
      <div className="selection-bar">
        <div className="selection-bar__checkbox">
          <span className="selection-bar__checkbox--icon"></span>
        </div>
        <p className="selection-bar__num">{number} Vehicles</p>
        <StraightBar />
        <div
          className="selection-bar__remove"
          // onClick={selectedConstant ? clearOnly : clearAndDeselect}
          onClick={clearOnly}
        >
          <Bin />
          <p className="selection-bar__remove--text">
            {/* {selectedConstant ? "Clear All Selections" : "Remove"} */}
            Clear All Selections
          </p>
        </div>
      </div>
      <div className="selection-warning">Select Upto Maximum 20 cars</div>
    </div>
  );
}
