import "./SidebarMenu.scss";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import SidebarMenuItem from "../sidebarMenuItem/SidebarMenuItem";
import speedDating from "../../assets/icons/sidebar-speed-dating-default.svg";
import speedDatingSelected from "../../assets/icons/sidebar-speed-dating-selected.svg";
import matches from "../../assets/icons/sidebar-matches-default.svg";
import matchesSelected from "../../assets/icons/sidebar-matches-selected.svg";
import resources from "../../assets/icons/sidebar-resources-default.svg";
import resourcesSelected from "../../assets/icons/sidebar-resources-selected.svg";
import compare from "../../assets/icons/compare-icon.svg";
import compareSelected from "../../assets/icons/compare-icon-selected.svg";
import { trackUserEventService } from "../../services/userEvent";
import { BUTTON_CLICK_EVENT_TYPE } from "./../../constants/userEvents";
import { useAuthContext } from "../../contexts/authContext";
import UpdatePopup from "../updatePopup/updatePopup";
import { updateComparisonChartInfo } from "../../services/comparisonchart";

function SidebarMenu() {
  const { matchmaker, setLastChartId, currentComparisonChartState } =
    useAuthContext();
  const location = useLocation();
  const [unviewed, setUnviewed] = useState(false);
  const [showCompareChartWarning, setShowCompareChartWarning] = useState(false);
  const compareChartWarningText = "This feature is enabled after speed dating!";

  function onClick() {
    setUnviewed(false);
  }

  return (
    <>
      {showCompareChartWarning && (
        <div style={{ position: "absolute", top: "1rem", left: "65vw" }}>
          <UpdatePopup
            Popupshow={setShowCompareChartWarning}
            main={compareChartWarningText}
          />
        </div>
      )}
      <div className="sidebar-menu">
        <SidebarMenuItem
          icon={
            location.pathname.startsWith("/speed-dating")
              ? speedDatingSelected
              : speedDating
          }
          label="Speed Dating"
          to="/speed-dating"
          isSelected={location.pathname.startsWith("/speed-dating")}
        />
        <SidebarMenuItem
          icon={
            location.pathname.includes("/matches") ? matchesSelected : matches
          }
          label="Matches"
          to={`/matches`} // vehicleId should be passed to this component
          isSelected={location.pathname.includes("/matches")}
        />
        {/* <SidebarMenuItem
				icon={
					location.pathname === "/saved-vehicles"
						? savedVehiclesSelected
						: savedVehicles
				}
				label="Saved Vehicles"
				to="/saved-vehicles"
				isSelected={location.pathname === "/saved-vehicles"}
			/>  */}
        <SidebarMenuItem
          icon={location.pathname === "/compare" ? compareSelected : compare}
          label="Compare"
          to={matchmaker?.profileQuizMaeTrims?.length > 0 ? "/compare" : ""}
          isSelected={location.pathname === "/compare"}
          onClick={() => {
            if (matchmaker?.profileQuizMaeTrims?.length === 0) {
              setShowCompareChartWarning(true);
            }
            trackUserEventService(
              BUTTON_CLICK_EVENT_TYPE,
              "Compare Button Clicked in Sidebar"
            );
            async function callToUpdateCCState() {
              const res = await updateComparisonChartInfo(
                currentComparisonChartState
              );
              if (res.comparisonChartUserState) {
                setLastChartId(res.comparisonChartUserState._id);
              }
            }
            callToUpdateCCState();
          }}
        />
        <SidebarMenuItem
          icon={
            location.pathname === "/resources" ? resourcesSelected : resources
          }
          label="Resources"
          to="https://www.makingautoeasy.com/freebies"
          isSelected={location.pathname === "/resources"}
        />
      </div>
    </>
  );
}

export default SidebarMenu;
