import "../../styles/buttonsStyles/buttonsStyles.scss";
import { ButtonSpinLoader } from "../buttonSpinLoader/buttonSpinLoader";
function ButtonGradient({ style = "", text, onClick }) {
  const buttonClass = `button__gradient${style ? `--${style}` : ""}`;
  return (
    <button className={buttonClass} onClick={onClick}>
      <ButtonSpinLoader />
      <span style={{ marginBlock: "auto" }}>{text}</span>
    </button>
  );
}

export default ButtonGradient;
