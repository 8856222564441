import "./SidebarAccountInfo.scss";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import defaultIcon from "../../assets/icons/profile-default.svg";
import profileIcon from "../../assets/icons/profile-logged-in.svg";

function SidebarAccountInfo() {
  const { isLoggedIn, user } = useAuthContext();
  const googleProfileImage = user?.googleProfileImage;
  return (
    <div className="user-info">
      <div className="user-info__wrapper">
        <img
          className="user-info__avatar"
          src={googleProfileImage ? googleProfileImage : profileIcon}
          alt=""
        />
        {isLoggedIn ? (
          <Link to="/my-profile" className="user-info__name">
            My Profile
          </Link>
        ) : (
          <Link to="/signin" className="user-info__name">
            Sign In
          </Link>
        )}
      </div>
    </div>
  );
}

export default SidebarAccountInfo;
