import "./SpeedDatingLoading.scss";
// import Lottie from "react-lottie";
import Lottie from "lottie-react";
import loadingAnimation from "../../data/loading-lottie.json";

function SpeedDatingLoading() {
  return (
    <div className="loading">
      <div className="loading__video">
        <Lottie animationData={loadingAnimation} />
      </div>
      <h2 className="loading__title">Gathering Your Matches</h2>
    </div>
  );
}

export default SpeedDatingLoading;
