export const getCarsInfoFromId = async (
  carMaeTrimIds,
  userSelectedFeatures
) => {
  const response = await fetch(`/api/mdp/getmdpcarsbyid`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      maeTrimIds: carMaeTrimIds,
      userSelectedFeatures: userSelectedFeatures,
    }),
  });
  const res = await response.json();
  if (response.ok) {
    console.log("Got the vehicle details");
    return res;
  } else {
    console.error("Failed to get vehicle details", res.error);
  }
};

export const generateBestMaeTrimIdFromMake = async (
  carMake,
  carModel,
  userSelectedFeatures
) => {
  try {
    const response = await fetch("/api/mdp/getbestmaetrimidbymake", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        carMake,
        carModel,
        userSelectedFeatures,
      }),
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.log("Error:", error.message);
    return;
  }
};
