export const makesandmodels = {
  Chevrolet: [
    "Equinox",
    "Trailblazer",
    "Malibu",
    "Trax",
    "Blazer",
    "Corvette",
    "Camaro",
    "Blazer EV",
    "Suburban",
    "Tahoe",
    "Traverse",
  ],
  Ford: [
    "Mustang",
    "Edge",
    "Expedition",
    "Bronco",
    "Bronco Sport",
    "Escape",
    "Explorer",
    "Mustang Mach-E",
  ],
  GMC: ["HUMMER EV SUV", "Terrain", "Yukon", "Yukon XL"],
  Maserati: ["GranTurismo", "Quattroporte", "Ghibli"],
  Cadillac: [
    "LYRIQ",
    "XT4",
    "XT6",
    "XT5",
    "CT4",
    "CT4-V",
    "CT5",
    "CT5-V",
    "Escalade",
    "Escalade ESV",
  ],
  BMW: [
    "M8",
    "8 Series",
    "M4",
    "4 Series",
    "X5",
    "X6",
    "iX",
    "M3",
    "X5 M",
    "X6 M",
    "X7",
    "XM",
    "i7",
    "7 Series",
    "i4",
    "5 Series",
    "i5",
    "3 Series",
    "Z4",
    "X3 M",
    "X4 M",
    "2 Series",
    "M2",
    "X1",
    "X4",
    "X3",
    "X2",
  ],
  "Alfa Romeo": ["Tonale", "Stelvio", "Giulia"],
  Audi: [
    "Q8 e-tron",
    "Q8 e-tron Sportback",
    "Q3",
    "A6 Sedan",
    "A6 allroad",
    "S6 Sedan",
    "A8",
    "S8",
    "RS 6 Avant",
    "S4 Sedan",
    "Q4 e-tron",
    "Q4 e-tron Sportback",
    "e-tron GT",
    "A7 Sportback",
    "S5 Cabriolet",
    "S5 Coupe",
    "S5 Sportback",
    "A5 Cabriolet",
    "A5 Coupe",
    "A5 Sportback",
    "A4 Sedan",
    "RS e-tron GT",
    "S3 Sedan",
    "RS 5 Coupe",
    "RS 5 Sportback",
    "S7 Sportback",
    "A3 Sedan",
    "Q7",
    "Q5",
    "Q5 Sportback",
    "RS 7",
    "SQ5",
    "SQ5 Sportback",
    "A4 allroad",
    "RS Q8",
    "SQ7",
    "SQ8 e-tron",
    "SQ8 e-tron Sportback",
    "SQ8",
    "Q8",
  ],
  Mazda: [
    "CX-5",
    "CX-90 PHEV",
    "CX-90 MHEV",
    "Mazda3",
    "CX-50",
    "Mazda3 Sport",
    "CX-30",
    "MX-5",
    "MX-5 RF",
    "MX-30 EV",
  ],
  Subaru: ["Crosstrek", "Outback", "Legacy", "Impreza", "Ascent", "Forester"],
  Toyota: [
    "Sequoia",
    "Camry",
    "GR Supra",
    "Corolla",
    "Corolla Hatchback",
    "Grand Highlander",
    "Sienna",
    "Prius",
    "Prius Prime",
    "GR Corolla",
    "RAV4",
    "4Runner",
    "Crown",
    "Highlander",
    "Corolla Cross",
    "Venza",
    "GR86",
    "bZ4X",
    "RAV4 Prime",
    "Land Cruiser",
  ],
  Buick: ["Encore GX", "Enclave", "Envista", "Envision"],
  MINI: ["Countryman", "5 Door", "3 Door", "Convertible"],
  Volkswagen: [
    "Atlas",
    "Atlas Cross Sport",
    "Jetta",
    "Taos",
    "Tiguan",
    "Golf R",
    "Jetta GLI",
    "Golf GTI",
    "ID.4",
  ],
  Lexus: [
    "NX",
    "UX",
    "LC",
    "TX",
    "RX",
    "ES",
    "RC",
    "LX",
    "RC F",
    "IS",
    "LS",
    "RZ",
    "GX",
  ],
  Kia: [
    "Seltos",
    "Carnival",
    "Soul",
    "Niro EV",
    "Telluride",
    "K5",
    "Niro",
    "Niro Plug-In Hybrid",
    "Sportage",
    "EV6",
    "Sportage Plug-In Hybrid",
    "Forte",
    "Forte5",
    "Sportage Hybrid",
    "EV9",
    "Sorento Plug-In Hybrid",
    "Sorento Hybrid",
  ],
  Honda: [
    "HR-V",
    "CR-V",
    "CR-V Hybrid",
    "Civic Sedan",
    "Civic Si Sedan",
    "Odyssey",
    "Civic Hatchback",
    "Pilot",
    "Accord Sedan",
    "Accord Hybrid",
    "Passport",
    "Civic Type R",
  ],
  "Land Rover": [
    "Range Rover Sport",
    "Range Rover Velar",
    "Defender",
    "Discovery",
    "Range Rover",
    "Discovery Sport",
    "Range Rover Evoque",
  ],
  Dodge: ["Hornet", "Durango"],
  Acura: ["Integra", "MDX", "RDX", "TLX"],
  "Mercedes-Benz": [
    "GLA",
    "C-Class",
    "GLE",
    "GLC",
    "GLB",
    "CLA",
    "GLS",
    "G-Class",
    "S-Class",
    "EQE",
    "EQS",
    "AMG GT",
    "EQB",
  ],
  Lincoln: ["Nautilus", "Navigator", "Navigator L", "Corsair", "Aviator"],
  Volvo: [
    "XC60",
    "XC60 Recharge",
    "XC90",
    "XC90 Recharge",
    "V60 Recharge",
    "V60 Cross Country",
    "S60",
    "S60 Recharge",
    "XC40 Recharge Pure Electric",
    "XC40",
    "S90",
    "S90 Recharge",
    "C40 Recharge Pure Electric",
    "V90 Cross Country",
  ],
  Genesis: [
    "GV80",
    "G80",
    "GV60",
    "Electrified GV70",
    "GV70",
    "G90",
    "Electrified G80",
    "G70",
  ],
  Nissan: [
    "LEAF",
    "Pathfinder",
    "GT-R",
    "Altima",
    "Murano",
    "Versa",
    "Z",
    "Kicks",
    "Armada",
    "Sentra",
    "ARIYA",
    "Rogue",
  ],
  INFINITI: ["QX80", "QX50", "QX55", "QX60", "Q50"],
  Chrysler: ["Grand Caravan", "Pacifica", "Pacifica Hybrid"],
  Jaguar: ["F-TYPE", "I-PACE", "E-PACE", "XF", "F-PACE"],
  Jeep: [
    "Wrangler",
    "Wrangler 4xe",
    "Grand Cherokee 4xe",
    "Grand Cherokee",
    "Grand Cherokee L",
    "Grand Wagoneer",
    "Grand Wagoneer L",
    "Wagoneer",
    "Wagoneer L",
    "Compass",
  ],
  Hyundai: [
    "Palisade",
    "Kona",
    "Tucson Plug-In Hybrid",
    "Tucson Hybrid",
    "Tucson",
    "IONIQ 6",
    "Elantra",
    "Elantra Hybrid",
    "Venue",
    "IONIQ 5",
    "Kona Electric",
    "Sonata",
  ],
  Porsche: [
    "718 Cayman",
    "Macan",
    "911",
    "718 Boxster",
    "Taycan",
    "718 Spyder",
    "Cayenne",
    "Panamera",
  ],
  Polestar: ["2"],
  Mitsubishi: ["Eclipse Cross", "Mirage", "Outlander", "RVR", "Outlander PHEV"],
  FIAT: ["500e"],
  Tesla: ["Model S", "Model 3", "Model X", "Model Y"],
  VinFast: ["VF 8"],
};
