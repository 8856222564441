import { useState, useEffect } from "react";
import "./profileSettings.scss";
import { questions } from "../../data/quiz";
import { EditQuizResponse } from "../editQuizReponse/editQuizResponse";
import { useAuthContext } from "../../contexts/authContext";
import UpdatePopup from "../updatePopup/updatePopup";
import ButtonGradient from "../buttonGradient/ButtonGradient";

import {
  MATCHMAKER_UPDATED,
  PROFILE_UPDATED,
} from "../../constants/lastActions";
import {
  BUDGET_QUIZ_INDEX,
  MAE_FEATURES_QUIZ_INDEX,
} from "../../constants/quizIndex";
import LoadingScreen from "../loadingScreen/loadingScreen";

function PopupUpdate({ PopupType, setPopupShow }) {
  const profileUpdatedMessage = "Profile Updated";
  const matchesGeneratedUpdateText =
    "New preferences have updated your matches!";
  const noMatchesGeneratedUpdateText =
    "New preferences have been saved. If you are searching for new Matches, we recommend adjusting your desired engine type or budget.";
  return (
    <>
      <div className="popup_position">
        <UpdatePopup
          Popupshow={setPopupShow}
          main={profileUpdatedMessage}
          sub={
            PopupType === "Changes"
              ? matchesGeneratedUpdateText
              : noMatchesGeneratedUpdateText
          }
        />
      </div>
    </>
  );
}

export const ProfileSettings = () => {
  const {
    matchmaker,
    setMatchmaker,
    printMatchmaker,
    setLastAction,
    user,
    setUser,
  } = useAuthContext();
  const [screenfreezed, setScreenFreezed] = useState(true);
  const [quizQuestions, setQuizQuestions] = useState(
    JSON.parse(JSON.stringify(questions))
  );
  const [dynamicQuizQuestions, setDynamicQuizQuestions] = useState(
    JSON.parse(JSON.stringify(questions))
  );
  const [currQuizResponses, setQuizResponses] = useState(
    matchmaker ? matchmaker.profileQuiz : []
  );

  console.log(currQuizResponses);

  const [PopupType, setPopupType] = useState("");
  const [Popup, setPopup] = useState(false);
  const [dragOff, setDragOff] = useState(true);
  const [saveBtnLoading, setSaveBtnLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const compareChanges = () => {
    const stateCopy = JSON.parse(JSON.stringify(dynamicQuizQuestions));
    for (let index = 0; index < currQuizResponses.length; index++) {
      if (
        stateCopy[index].currSelected.length <
        stateCopy[index].selectionRange[0]
      )
        return true;
      const featureFlag =
        currQuizResponses[index].question ===
        "Lastly, what features would you like in your car?";
      for (let i = 0; i < stateCopy[index].options.length; i++) {
        const curr = stateCopy[index].options[i];
        if (featureFlag && stateCopy[index].options[i].field) {
          const featureKey = stateCopy[index].options[i].field;
          if (
            curr.selected !==
            currQuizResponses[index].selectedOptions[0][featureKey]
          ) {
            return false;
          }
        } else if (
          (curr.selected &&
            !currQuizResponses[index].selectedOptions.includes(curr.text)) ||
          (!curr.selected &&
            currQuizResponses[index].selectedOptions.includes(curr.text))
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const saveChangesCompleted = (btnSpinner) => {
    setPopup(true);
    btnSpinner.className = "hide-spin-loader";
    setDragOff(!dragOff);
    setScreenFreezed(!screenfreezed);
  };

  const editProfileAPI = async (quizSelections, btnSpinner) => {
    try {
      const response = await fetch("/api/profile/editProfile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(quizSelections),
      });
      if (response.ok) {
        let res = await response.json();
        let matchmaker = res.matchmaker;
        user.lastUpdatedQuizAnswers = res.lastUpdatedQuizAnswers;
        console.log(matchmaker);
        console.log("New Matchmaker");
        setUser(user);
        setLastAction(PROFILE_UPDATED);
        setQuizResponses(matchmaker.profileQuiz);
        setMatchmaker(matchmaker);
        setLastAction(MATCHMAKER_UPDATED);
        printMatchmaker();
      } else {
        const errorData = await response.json();
        console.log("Failed:", errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
    saveChangesCompleted(btnSpinner);
  };

  const onSaveChanges = async () => {
    const btnSpinner = document.getElementById("btn-spin-loader");
    btnSpinner.className = "show-spin-loader";
    const compareFlag = compareChanges();
    const quizSelections = {
      selected: [],
      profileUpdateTime: new Date(),
    };

    for (let i = 0; i < dynamicQuizQuestions.length; i++) {
      var selectedOptions = [];
      var question = dynamicQuizQuestions[i];
      if (i == MAE_FEATURES_QUIZ_INDEX) {
        const featureOptions = {};
        question.options.forEach((option) => {
          if (option.field) {
            featureOptions[option.field] = option.selected;
          }
        });
        quizSelections.selected.push({
          question: question.text,
          selectedOptions: [featureOptions],
        });
      } else {
        question.options.forEach((option) => {
          if (option.selected) {
            selectedOptions.push(option.text);
          }
        });
        quizSelections.selected.push({
          question: question.text,
          selectedOptions: selectedOptions,
        });
      }
    }
    if (!compareFlag) {
      editProfileAPI(quizSelections, btnSpinner);
      setPopupType("Changes");
    } else {
      setPopupType("No changes");
      saveChangesCompleted(btnSpinner);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const selectOption = (index, optionIndex) => {
    const stateCopy = JSON.parse(JSON.stringify(dynamicQuizQuestions));
    const selectedOptions = stateCopy[index].currSelected;
    const maxOptionLen = stateCopy[index].selectionRange[1];
    let selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
    if (selectedOptions.length >= maxOptionLen) {
      const removedOptionInfo = selectedOptions.shift();
      stateCopy[index].options[removedOptionInfo].selected = false;
    }
    if (selectedOptionsIndex === -1 && selectedOptions.length < maxOptionLen) {
      selectedOptions.push(optionIndex);
      stateCopy[index].options[optionIndex].selected = true;
    }
    setDynamicQuizQuestions(stateCopy);
  };

  const unselectOption = (index, optionIndex) => {
    const stateCopy = JSON.parse(JSON.stringify(dynamicQuizQuestions));
    const selectedOptions = stateCopy[index].currSelected;
    let selectedOptionsIndex = selectedOptions.indexOf(optionIndex);
    if (selectedOptionsIndex !== -1) {
      stateCopy[index].options[optionIndex].selected = false;
      stateCopy[index].currSelected.splice(selectedOptionsIndex, 1);
    }
    setDynamicQuizQuestions(stateCopy);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!currQuizResponses) return;
    const stateCopy = JSON.parse(JSON.stringify(dynamicQuizQuestions));
    currQuizResponses.forEach((element, index) => {
      const featureFlag = index === MAE_FEATURES_QUIZ_INDEX;
      for (let i = 0; i < stateCopy[index].options.length; i++) {
        if (featureFlag && stateCopy[index].options[i].field) {
          const featureKey = stateCopy[index].options[i].field;
          const isSelectedFeature = element.selectedOptions[0][featureKey];
          stateCopy[index].options[i].selected = isSelectedFeature;
          if (isSelectedFeature && !stateCopy[index].currSelected.includes(i)) {
            stateCopy[index].currSelected.push(i);
          }
        }
        if (
          !featureFlag &&
          element.selectedOptions.includes(stateCopy[index].options[i].text)
        ) {
          stateCopy[index].options[i].selected = true;
          if (stateCopy[index].text === "What’s your ideal budget?")
            stateCopy[index].currSelected = [i];
          else if (
            stateCopy[index].currSelected.length <
            element.selectedOptions.length
          ) {
            stateCopy[index].currSelected.push(i);
          }
        }
      }
    });
    setDynamicQuizQuestions(JSON.parse(JSON.stringify(stateCopy)));
    setIsLoading(false);
  }, [matchmaker]);

  useEffect(() => {
    console.log("Updated state:", dynamicQuizQuestions);
  }, [dynamicQuizQuestions]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {Popup ? (
        <PopupUpdate PopupType={PopupType} setPopupShow={setPopup} />
      ) : null}
      <div className="profile-settings">
        <div className="profile-settings__editbuttoncontainer">
          {screenfreezed && (
            <button
              onClick={() => {
                setScreenFreezed(!screenfreezed);
                setDragOff(false);
              }}
              className="edit-btn"
            >
              Edit Responses
            </button>
          )}
        </div>
        <div className="profile-settings__questioncontainer">
          {quizQuestions.map((ques, index) => {
            return (
              <EditQuizResponse
                key={index}
                index={index}
                desc={ques.shortDescription}
                options={dynamicQuizQuestions[index].options}
                isFreezed={screenfreezed}
                selectOption={selectOption}
                unselectOption={unselectOption}
                currSelected={dynamicQuizQuestions[index].currSelected}
              />
            );
          })}
        </div>
        {!screenfreezed && (
          <div className="profile-settings__savebuttoncontainer">
            <ButtonGradient text="Save Changes" onClick={onSaveChanges} />
          </div>
        )}
      </div>
    </>
  );
};
