import "./MdpCarDetails.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import seats from "../../assets/icons/mdp-seats.svg";
import wd from "../../assets/icons/mdp-WD.svg";
import transmission from "../../assets/icons/mdp-transmission.svg";
import engine from "../../assets/icons/mdp-engine.svg";
import thumbsUp from "../../assets/icons/mdp-thumbs-up.svg";
import { trackUserEventService } from "../../services/userEvent";
import { BUTTON_CLICK_EVENT_TYPE } from "../../constants/userEvents";

function MdpCarDetails({ vehicleDetails }) {
  console.log("vehicleDetails MDP Car Details", vehicleDetails);

  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const formattedEngineString = vehicleDetails.calculatedMDPFeatures.engine
    .map((engineType, index) => {
      let temp = engineType;
      if (engineType === "PHEV") {
        temp = "Plug-In Hybrid";
      }

      if (engineType === "HEV") {
        temp = "Hybrid";
      }

      return index < vehicleDetails.calculatedMDPFeatures.engine.length - 1
        ? `${temp}, `
        : temp;
    })
    .join("");

  console.log(formattedEngineString);

  return (
    <div className="car-details-wrapper">
      <div className="car-details">
        <h1 className="car-details__header">
          {vehicleDetails.vehicleInfo.make} {vehicleDetails.vehicleInfo.model}
        </h1>
        <div className="icon">
          <img className="icon__img" src={thumbsUp} alt="" />
          <p className="icon__text"> Recommended</p>
        </div>
      </div>
      <div className="social-buttons">
        {/* <img className="social-buttons__img" src={likeButton} alt="" /> */}
        {/* <img className="social-buttons__img" src={shareButton} alt="" />
        <img className="social-buttons__img" src={forwardButton} alt="" /> */}
      </div>
      <div className="car-highlights-wrapper">
        <h2 className="mdp-title">HIGHLIGHTS</h2>
        <div className="car-highlights">
          <p className="car-highlights__text">
            ${formatPrice(vehicleDetails.calculatedMDPFeatures.baseMSRP)}
          </p>
          <p className="car-highlights__title">MSRP Starting From</p>
        </div>
        <div className="car-highlights">
          <p className="car-highlights__text">
            {vehicleDetails.calculatedMDPFeatures.estimatedRangeByTank}
            km
          </p>
          <p className="car-highlights__title">Estimated Range / Tank</p>
        </div>
        <div className="car-highlights">
          <p className="car-highlights__text">
            ${formatPrice(vehicleDetails.calculatedMDPFeatures.priceOfFullTank)}
          </p>
          <p className="car-highlights__title">Tank of Gas at $1.90</p>
        </div>
      </div>
      <div>
        <div className="car-overview">
          <h2 className="mdp-title">OVERVIEW</h2>
        </div>
        <div className="car-features">
          <div className="car-features__card">
            <img className="car-features__card--img" src={seats} alt="" />
            <p className="car-features__card--text">
              {vehicleDetails.calculatedMDPFeatures.seats} Seats
            </p>
          </div>
          <div className="car-features__card">
            <img className="car-features__card--img" src={engine} alt="" />
            <p className="car-features__card--text">
              {formattedEngineString}{" "}
              {vehicleDetails.calculatedMDPFeatures.engine.length == 1
                ? "Engine"
                : "Engines"}
            </p>
          </div>{" "}
          <div className="car-features__card">
            <img className="car-features__card--img" src={wd} alt="" />
            <p className="car-features__card--text">
              {vehicleDetails.calculatedMDPFeatures.driveType} Drive
            </p>
          </div>{" "}
          <div className="car-features__card">
            <img
              className="car-features__card--img"
              src={transmission}
              alt=""
            />
            <p className="car-features__card--text">
              {vehicleDetails.calculatedMDPFeatures.transmissionType}{" "}
              Transmission
            </p>
          </div>
        </div>
        <div className={`search-box`}>
          <h4 className="search-box__title">Compare Feature</h4>
          <p className="search-box__text">
            {" "}
            Compare car options your way. Select what criteria impacts your
            decision and receive a customized chart for easy decision making.
          </p>
          <Link to="/compare" className="search-box__link">
            <button
              className="search-box__btn"
              onClick={() => {
                trackUserEventService(
                  BUTTON_CLICK_EVENT_TYPE,
                  "Compare Button Clicked in MDP"
                );
              }}
            >
              Compare
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MdpCarDetails;
