export const questions = [
  {
    header: "Create Your Profile",
    text: "Let's start - why are you looking for a new car?",
    shortDescription: "Reason for New Vehicle",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "My current car is aging.",
        subtext: "",
        greyicon: "quiz-clock-grey.svg",
        selected: false,
      },
      {
        text: "I need more space for my stuff.",
        subtext: "",
        greyicon: "quiz-upsize-grey.svg",
        selected: false,
      },
      {
        text: "New member in the family.",
        subtext: "",
        greyicon: "quiz-baby-grey.svg",
        selected: false,
      },
      {
        text: "Getting my first car!",
        subtext: "",
        greyicon: "quiz-firstcar-grey.svg",
        selected: false,
      },
      {
        text: "Treating Myself!",
        subtext: "",
        greyicon: "quiz-gift-grey.svg",
        selected: false,
      },
      {
        text: "Downsizing",
        subtext: "",
        greyicon: "quiz-downsize-grey.svg",
        selected: false,
      },
      {
        text: "Other",
        subtext: "",
        greyicon: "",
        selected: false,
      },
    ],
  },
  {
    header: "Create Your Profile",
    text: "Where are you in your car buying journey?",
    shortDescription: "Your Progress",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "Just Started",
        subtext: "I’m not sure what I’m looking for and I need help",
        greyicon: "quiz-start-grey.svg",
        selected: false,
      },
      {
        text: "Know a bit",
        subtext: "I have an idea of what I want, but could still use some help",
        greyicon: "quiz-search-grey.svg",
        selected: false,
      },
      {
        text: "Know what I want",
        subtext: "I basically know what I want, I just need help finding it",
        greyicon: "quiz-lightbulb-grey.svg",
        selected: false,
      },
    ],
  },
  {
    header: "Create Your Profile",
    text: "What type of vehicle are you looking for?",
    subtext: "Pick one, you can always change later",
    shortDescription: "Vehicle Type",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "Car",
        subtext: "",
        greyicon: "quiz-car-grey.svg",
        selected: false,
      },
      {
        text: "Small SUV",
        subtext: "5 seater with just enough trunk space for everyday needs",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Medium SUV",
        subtext: "Slightly larger 5 seater with more trunk space",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      {
        text: "Large SUV",
        subtext: "The roomiest 2 row or 3 row SUVs for larger families",
        greyicon: "quiz-suv-grey.svg",
        selected: false,
      },
      // {
      //   text: "Truck",
      //   subtext: "",
      //   greyicon: "quiz-truck-grey.svg",
      //   selected: false,
      // },
      {
        text: "Minivan",
        subtext: "",
        greyicon: "quiz-minivan-grey.svg",
        selected: false,
      },
    ],
  },
  {
    header: "Create Your Profile",
    text: "What are your driving conditions?",
    shortDescription: "Driving Conditions",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "Fair Weather Zoomer",
        subtext: "",
        greyicon: "quiz-sunny-grey.svg",
        selected: false,
      },
      {
        text: "It Snows Frequently",
        subtext: "",
        greyicon: "quiz-snowman-grey.svg",
        selected: false,
      },
      {
        text: "Off-roading is my jam",
        subtext: "",
        greyicon: "quiz-offroading-grey.svg",
        selected: false,
      },
    ],
  },
  // {
  //   header: "Create Your Profile",
  //   text: "For trunk space, what do you need to carry?",
  //   shortDescription: "Trunk Space",
  //   currSelected: [],
  //   selectionRange: [1, 1],
  //   options: [
  //     {
  //       text: "Groceries, luggage etc.",
  //       subtext: "",
  //       greyicon: "quiz-bags-grey.svg",
  //       selected: false,
  //     },
  //     {
  //       text: "Bigger things like strollers or large pets.",
  //       subtext: "",
  //       greyicon: "quiz-stroller-grey.svg",
  //       selected: false,
  //     },
  //     {
  //       text: "Large items like bikes or large sports equipment.",
  //       subtext: "",
  //       greyicon: "quiz-bicycle-grey.svg",
  //       selected: false,
  //     },
  //   ],
  // },
  // {
  //   header: "Create Your Profile",
  //   text: "Roughly how bougie or luxurious is your ideal car?",
  //   shortDescription: "Desired Bouginess",
  //   currSelected: [],
  //   selectionRange: [1, 1],
  //   options: [
  //     {
  //       text: "Bare Necessities",
  //       subtext:
  //         "As long as it can get you from A to B, that’s all that matters to you",
  //       greyicon: "quiz-bare-necessities-grey.svg",
  //       selected: false,
  //     },
  //     {
  //       text: "Basic",
  //       subtext:
  //         "Convenient features like heated seats, apple car play, and safe driving features",
  //       greyicon: "quiz-basic-grey.svg",
  //       selected: false,
  //     },
  //     {
  //       text: "Fancier",
  //       subtext:
  //         "Feeling fancy with things like a push start, heated steering wheel, sunroof, power liftgate",
  //       greyicon: "quiz-fancier-grey.svg",
  //       selected: false,
  //     },
  //     {
  //       text: "All the bells and whistles",
  //       subtext:
  //         "Show off sweet features like cooling seats, navigation, hands free trunk",
  //       greyicon: "quiz-bells-and-whistles-grey.svg",
  //       selected: false,
  //     },
  //   ],
  // },
  {
    header: "Create Your Profile",
    text: "How do you want your vehicle powered?",
    subtext: "Select all your preference(s)",
    shortDescription: "Vehicle Engine",
    currSelected: [],
    selectionRange: [1, 5],
    options: [
      {
        text: "Gasoline",
        subtext: "",
        greyicon: "quiz-gas-grey.svg",
        selected: false,
      },
      {
        text: "Hybrid",
        subtext: "",
        greyicon: "quiz-hybrid-grey.svg",
        selected: false,
      },
      {
        text: "Plug-in Hybrid",
        subtext: "",
        greyicon: "quiz-plugin-grey.svg",
        selected: false,
      },
      {
        text: "Full electric",
        subtext: "",
        greyicon: "quiz-ev-grey.svg",
        selected: false,
      },
      {
        text: "Diesel",
        subtext: "",
        greyicon: "quiz-diesel-grey.svg",
        selected: false,
      },
    ],
  },
  {
    header: "Create Your Profile",
    text: "What’s your ideal budget?",
    type: "budget",
    shortDescription: "Budget",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "$10,000 - $20,000",
        subtext: "",
        selected: false,
        value: 20000,
      },
      {
        text: "$20,000 - $30,000",
        subtext: "",
        selected: false,
        value: 30000,
      },
      {
        text: "$30,000 - $40,000",
        subtext: "",
        selected: false,
        value: 40000,
      },
      {
        text: "$40,000 - $50,000",
        subtext: "",
        selected: false,
        value: 50000,
      },
      {
        text: "$50,000 - $60,000",
        subtext: "",
        selected: false,
        value: 60000,
      },
      {
        text: "$60,000 - $70,000",
        subtext: "",
        selected: false,
        value: 70000,
      },
      {
        text: "$70,000 - $80,000",
        subtext: "",
        selected: false,
        value: 80000,
      },
      {
        text: "$80,000+",
        subtext: "",
        selected: false,
        value: 100000,
      },
    ],
  },
  {
    header: "Create Your Profile",
    text: "When do you want your new car?",
    shortDescription: "Timeline",
    currSelected: [],
    selectionRange: [1, 1],
    options: [
      {
        text: "ASAP",
        subtext: "",
        greyicon: "quiz-asap-grey.svg",
        selected: false,
      },
      {
        text: "2 - 3 Months",
        subtext: "",
        greyicon: "quiz-23months-grey.svg",
        selected: false,
      },
      {
        text: "6 Months",
        subtext: "",
        greyicon: "quiz-6months-grey.svg",
        selected: false,
      },
      {
        text: "Within a Year",
        subtext: "",
        greyicon: "quiz-year-grey.svg",
        selected: false,
      },
      {
        text: "12-16 months",
        subtext: "",
        greyicon: "quiz-nextyear-grey.svg",
        selected: false,
      },
      {
        text: "I'm Just Browsing",
        subtext: "",
        greyicon: "quiz-browse-grey.svg",
        selected: false,
      },
    ],
  },
  {
    header: "Feature Request",
    text: "Lastly, what features would you like in your car?",
    subtext:
      "Please select at least 3 features. Don’t worry, these are not deal-breakers for what we recommend you!",
    shortDescription: "Feature Request",
    currSelected: [],
    selectionRange: [3, 14],
    options: [
      {
        text: "Heated Seats",
        subtext: "",
        greyicon: "feature-heated-seats-grey.svg",
        selected: false,
        field: "heatedSeats",
      },
      {
        text: "Cooling Seats",
        subtext: "",
        greyicon: "feature-cooling-seats-grey.svg",
        selected: false,
        field: "coolingSeats",
      },
      {
        text: "Heated Steering Wheel",
        subtext: "",
        greyicon: "feature-heated-steering-wheel-grey.svg",
        selected: false,
        field: "heatedSteeringWheel",
      },
      {
        text: "Push Start and Keyless Entry",
        subtext: "",
        greyicon: "feature-push-start-grey.svg",
        selected: false,
        field: "pushStartAndKeylessEntry",
      },
      {
        text: "Leather/Leatherette Seats",
        subtext: "",
        greyicon: "feature-leather-cloth-seats-grey.svg",
        selected: false,
        field: "leatherSeats",
      },
      // {
      //   text: "Keyless Entry",
      //   subtext: "",
      //   greyicon: "feature-keyless-entry-grey.svg",
      //   selected: false,
      //   field: "pushStartAndKeylessEntry",
      // },
      {
        text: "Seat Memory",
        subtext: "",
        greyicon: "feature-seat-memory-grey.svg",
        selected: false,
        field: "seatMemory",
      },
      {
        text: "Power Seats",
        subtext: "",
        greyicon: "feature-power-seats-grey.svg",
        selected: false,
        field: "powerSeats",
      },
      {
        text: "Apple CarPlay/Android Auto",
        subtext: "",
        greyicon: "feature-carplay-grey.svg",
        selected: false,
        field: "appleCarplay",
      },
      {
        text: "Sun Roof",
        subtext: "",
        greyicon: "feature-sunroof-grey.svg",
        selected: false,
        field: "sunroof",
      },
      {
        text: "Remote Start",
        subtext: "",
        greyicon: "feature-auto-start-grey.svg",
        selected: false,
        field: "remoteStart",
      },
      {
        text: "Power Liftgate",
        subtext: "",
        greyicon: "feature-power-liftgate-grey.svg",
        selected: false,
        field: "powerLiftgate",
      },
      {
        text: "Power Driver Seat",
        subtext: "",
        greyicon: "feature-power-seats-grey.svg",
        selected: false,
        field: "powerDriverSeat",
      },
      // {
      //   text: "Driver Seat Memory",
      //   subtext: "",
      //   greyicon: "feature-seat-memory-grey.svg",
      //   selected: false,
      //   field: "driverSeatMemory",
      // },
    ],
  },
];
