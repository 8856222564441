import "./MDP.scss";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import LoadingScreen from "../../components/loadingScreen/loadingScreen";
import MdpCarDetails from "../../components/mdpCarDetails/MdpCarDetails";
import MdpGallery from "../../components/mdpGallery/MdpGallery";
import Sidebar from "../../components/sidebar/Sidebar";
import MdpDrawers from "../../components/mdpDrawers/MdpDrawers";
import { trackUserEventService } from "../../services/userEvent";
import {
  MDP_CLICK_EVENT_TYPE,
  MDP_VIEW_TIME_EVENT_TYPE,
} from "../../constants/userEvents";
import { getCarsInfoFromId } from "../../services/mdp";

function MDP() {
  const { matchmaker } = useAuthContext();
  const { maeTrimId } = useParams();
  const [vehicleDetails, setVehicleDetails] = useState(null);
  const [pageViewStartTime, setPageViewStartTime] = useState(Date.now());
  const [loading, setLoading] = useState(true);

  console.log("vehicleId", maeTrimId);

  useEffect(() => {
    trackUserEventService(MDP_CLICK_EVENT_TYPE, `/${maeTrimId}`);

    return () => {
      const pageViewEndTime = Date.now();
      const timeSpentInSeconds = (pageViewEndTime - pageViewStartTime) / 1000;
      trackUserEventService(MDP_VIEW_TIME_EVENT_TYPE, {
        page: `/${maeTrimId}`,
        timeSpent: timeSpentInSeconds,
      });
    };
  }, []);

  useEffect(() => {
    const fetchCarDetails = async () => {
      const details = await getCarsInfoFromId(
        [maeTrimId],
        matchmaker.profileQuizMaeTrims[0].totalFeatures
      );
      console.log(details, "details");
      setVehicleDetails(details.carsInfo[0]);
      setLoading(false);
    };
    fetchCarDetails();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="mdp-page">
      <Sidebar />
      <div className="mdp">
        <div className="left">
          <Link to="/matches" className="back-button-div-desktop">
            <div className="back-button">Back</div>
          </Link>
          <div className="car-image-desktop">
            <MdpGallery vehicleDetails={vehicleDetails} />
          </div>

          <MdpDrawers vehicleDetails={vehicleDetails} />
        </div>
        <div className="right">
          <Link to="/matches" className="back-button-div-mobile">
            <div className="back-button">Back</div>
          </Link>
          <div className="car-image-mobile">
            <MdpGallery vehicleDetails={vehicleDetails} />
          </div>

          <MdpCarDetails vehicleDetails={vehicleDetails} />
        </div>
      </div>
    </div>
  );
}

export default MDP;
