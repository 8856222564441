import { useState, useEffect } from "react";
import "./HamburgerMenuItem.scss";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";

function HamburgerMenuItem({ icon, label, to, onClick }) {
  const { matchmaker } = useAuthContext();
  const [newMatchesUpdate, setNewMatchesUpdate] = useState(false);
  const [sendTo, setSendTo] = useState(to);

  useEffect(() => {
    const carsToDate = matchmaker?.profileQuizMaeTrims.filter((cars) => {
      return !cars.liked && !cars.disliked;
    });
    if (!matchmaker) return;
    if (
      matchmaker?.profileQuizMaeTrims?.length > 0 &&
      carsToDate &&
      carsToDate.length > 0
    ) {
      setNewMatchesUpdate(true);
      if (to === "/speed-dating") {
        setSendTo("/speed-dating/quiz");
      }
    } else {
      setNewMatchesUpdate(false);
    }
  }, [matchmaker]);

  const itemClass = `hamburger-menu-item`;

  const isExternal = to.startsWith("http://") || to.startsWith("https://");

  const content = (
    <div className={itemClass}>
      <span className="hamburger-menu-item__label">{label}</span>
    </div>
  );

  // Render for external links
  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" onClick={onClick}>
        {content}
      </a>
    );
  }

  // Render for internal links
  return (
    <Link to={sendTo} onClick={onClick}>
      {content}
    </Link>
  );
}

export default HamburgerMenuItem;
