import "../../styles/buttonsStyles/buttonsStyles.scss";

const ButtonPlain = ({
  color,
  border,
  padding,
  children,
  onClick,
  ...props
}) => (
  <button
    className="button__plain"
    style={{
      backgroundColor: color,
      border: border,
      padding: padding,
    }}
    onClick={onClick}
    {...props}
  >
    {children}
    {props.text}
  </button>
);

export default ButtonPlain;
