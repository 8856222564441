import "./carCard.scss";
import { useNavigate, Link } from "react-router-dom";
import { formatPrice } from "../../utils/formatUtils";
import { ReactComponent as CompareSelected } from "../../assets/icons/compare-selected.svg";
import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as Dislike } from "../../assets/icons/dislike.svg";
import { ReactComponent as LikeNotActive } from "../../assets/icons/like-not-active.svg";
import { ReactComponent as DislikeNotActive } from "../../assets/icons/dislike-not-active.svg";
import { ReactComponent as HeartRed } from "../../assets/icons/bookmark.svg";
import CarCompatibilityInfo from "../carCompatibilityInfo/carCompatibilityInfo";

const  CarCard = ({cardSelectionOnClick, isCardSelected, isCardSelectable, isCardLinkingToMDP, carInfo, likeOnClick, dislikeOnClick, isDisliked, isLiked, showLikeDislike, isCompatibilityVisible }) => {
  return (
    <div
    className={`car-card ${isCardSelected ? "car-card__selected" : ""}`}
    onClick={() => {cardSelectionOnClick(carInfo._maeTrimId || carInfo.vehicleInfo._maeTrimId)}}
  >
    <Link
      to={isCardLinkingToMDP ?`/maetrims/${carInfo._maeTrimId}` : "" }
      key={carInfo._maeTrimId}
    >
    
      <div className="car-card__thumbnail">
        {isCardSelectable && (
          <div className="car-card__thumbnail--emptyselection"></div>
        )}
        {isCardSelected && (
          <div className="car-card__thumbnail--emptyselection">
            <CompareSelected />
          </div>
        )}
        <div className="car-card__thumbnail--heart">
          {isLiked && <HeartRed />}
        </div>
        <img
          className="car-card__thumbnail--img"
          src={carInfo.vehicleInfo.amazonImage}
          alt={carInfo.calculatedMDPFeatures.maeTrimTitle}
        />
      </div>
      
    
    <div className="car-card__details">
      <p className="car-card__details--title">
        {carInfo.calculatedMDPFeatures.maeTrimTitle}
      </p>
      <div className="car-card__details--price">
        <p className="car-card__details--price-dollarsign">$</p>
        <p className="car-card__details--price-number">
          {formatPrice(carInfo.vehicleInfo.baseMSRP)}
        </p>{" "}
      </div>
    </div>
    
    {isCompatibilityVisible ? <CarCompatibilityInfo carInfo={carInfo}/> : null}

    </Link>

    <div className="car-card__like-dislike-container">
      {showLikeDislike ? isLiked? 
         (
          <Like onClick={() => {}} />
        ) : (<LikeNotActive onClick={() => {likeOnClick(carInfo._maeTrimId)}} />) : null}
      {showLikeDislike ? isDisliked? 
         (
          <Dislike onClick={() => {}} />
        ) : <DislikeNotActive onClick={() => dislikeOnClick(carInfo._maeTrimId)}/> : null}
    </div>
  </div>
  
  );
}

export default CarCard;
