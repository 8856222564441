import "./MdpDimensions.scss";
import { useState } from "react";
import cargoImg from "../../assets/images/mdp-cargo-img.svg";
import legFirstRowImg from "../../assets/images/mdp-leg-first-row-img.svg";
import legSecondRowImg from "../../assets/images/mdp-leg-second-row-img.svg";
import headFirstRowImg from "../../assets/images/mdp-head-first-row-img.svg";
import headSecondRowImg from "../../assets/images/mdp-head-second-row-img.svg";
import MdpButtonCard from "../mdpButtonCard/MdpButtonCard";
import { formatToOneDecimal } from "../../utils/formatUtils.jsx";

function MdpDimensions({ vehicleDetails }) {
  const [selectedImage, setSelectedImage] = useState(cargoImg); // Default to the first image
  const [selectedTitle, setSelectedTitle] = useState("Trunk Cargo Volume"); // Initialize with the title of the first card

  const handleSelect = (title, selectedImg) => {
    setSelectedTitle(title); // Update the selected title
    setSelectedImage(selectedImg); // Update the selected image
  };

  const formattedCargoVolume =
    formatToOneDecimal(vehicleDetails.calculatedMDPFeatures.cargo) + "ft";

  return (
    <div className="dimensions">
      <div className="dimensions__left">
        <img
          src={selectedImage}
          alt="Selected"
          className="dimensions__left--image"
        />
      </div>
      <div className="dimensions__right">
        <div className="cargo">
          <h4 className="cargo__title">CARGO</h4>
          <MdpButtonCard
            title="Trunk Cargo Volume"
            details={formattedCargoVolume}
            isSelected={selectedTitle === "Trunk Cargo Volume"}
            onSelect={() => handleSelect("Trunk Cargo Volume", cargoImg)}
          />
        </div>
        <div className="cargo">
          <h4 className="cargo__title">LEG ROOM</h4>
          <MdpButtonCard
            title="1st Row"
            details={
              vehicleDetails.calculatedMDPFeatures.legRoomFirstRow + "ft"
            }
            isSelected={selectedTitle === "Leg 1st Row"}
            onSelect={() => handleSelect("Leg 1st Row", legFirstRowImg)}
          />
          <MdpButtonCard
            title="2nd Row"
            details={
              vehicleDetails.calculatedMDPFeatures.legRoomSecondRow + "ft"
            }
            isSelected={selectedTitle === "Leg 2nd Row"}
            onSelect={() => handleSelect("Leg 2nd Row", legSecondRowImg)}
          />
        </div>
        <div className="cargo">
          <h4 className="cargo__title">HEAD ROOM</h4>
          <MdpButtonCard
            title="1st Row"
            details={
              vehicleDetails.calculatedMDPFeatures.headRoomFirstRow + "ft"
            }
            isSelected={selectedTitle === "Head 1st Row"}
            onSelect={() => handleSelect("Head 1st Row", headFirstRowImg)}
          />
          <MdpButtonCard
            title="2nd Row"
            details={
              vehicleDetails.calculatedMDPFeatures.headRoomSecondRow + "ft"
            }
            isSelected={selectedTitle === "Head 2nd Row"}
            onSelect={() => handleSelect("Head 2nd Row", headSecondRowImg)}
          />
        </div>
      </div>
    </div>
  );
}

export default MdpDimensions;
