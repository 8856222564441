import ImageGallery from "react-image-gallery";
// import "~react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import "./MdpGallery.scss";
import missingCarImg from "../../assets/images/mdp-missing-car-img.svg";

function MdpGallery({ vehicleDetails }) {
  const imageUrl = vehicleDetails.vehicleInfo.amazonImage || missingCarImg;

  return (
    <div className="mdp-image-container">
      <div className="mdp-gallery">
        <img className="mdp-gallery__img" src={imageUrl} alt="" />
      </div>
    </div>
  );
}

export default MdpGallery;
