import "./SpeedDatingCard.scss";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { forwardRef, useImperativeHandle, useRef, useEffect } from "react";

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const SpeedDatingCard = forwardRef(({ card, handleSwipe }, ref) => {
  function currencyFormat(num) {
    return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const controls = useAnimation();
  const cardRef = useRef(null);

  // const swipeOut = (xValue) => {
  // 	controls
  // 		.start({
  // 			x: xValue,
  // 			opacity: 0,
  // 			transition: { duration: 0.5 },
  // 		})
  // 		.then(() => {
  // 			handleSwipe(xValue > 0 ? 101 : -101, card);
  // 		});
  // };

  const handleLike = () => {
    controls
      .start({
        x: 500,
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      })
      .then(() => {
        // Callback after animation is complete
        handleSwipe(101, card._id);
      });
  };

  const handleDislike = () => {
    controls
      .start({
        x: -500,
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      })
      .then(() => {
        // Callback after animation is complete
        handleSwipe(-101, card._id);
      });
  };

  useEffect(() => {
    controls.set({
      x: 0,
      opacity: 1,
    });
  }, [card, controls]);

  useImperativeHandle(ref, () => ({
    handleLike,
    handleDislike,
    resetPosition: () => {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      });
    },
  }));

  return (
    <AnimatePresence>
      <motion.div
        ref={cardRef}
        animate={controls}
        className="speed-dating-card"
        key={card.vehicleInfo._id}
        drag="x"
        dragConstraints={{
          left: 0,
          right: 0,
        }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            handleSwipe(-100, card.vehicleInfo._id);
          } else if (swipe > swipeConfidenceThreshold) {
            handleSwipe(100, card.vehicleInfo._id);
          }
        }}
        whileTap={{
          scale: 1.1,
        }}
        // exit={{ opacity: 0 }}
        // animate={{ x: 0, opacity: 1 }}
        // initial={{ opacity: 0 }}
      >
        <div className="card__image">
          <img
            className="card__image--img"
            src={card.vehicleInfo.amazonImage}
            alt=""
            draggable="false"
          />
        </div>
        <div className="card__details">
          <div className="card__details--top">
            <p className="card__details--text">
              {card.vehicleInfo.make} {card.vehicleInfo.model}
            </p>
          </div>
        </div>
        <div className="card__box">
          <p className="card__box--title">Base MSRP</p>
          <p className="card__box--label">
            {currencyFormat(card.vehicleInfo.baseMSRP)}
          </p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
});

export default SpeedDatingCard;
