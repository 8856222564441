import { useState } from "react";
import "./FormField.scss";

function FormField({ type, id, value, placeholder, onChange, rows }) {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    if (e.target.value !== "") {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  };

  return (
    <div
      className={`form-group ${isFocused || hasValue ? "has-content" : ""} ${
        type === "email" ? "mb-8" : ""
      }`}
    >
      <input
        className="form-field"
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        rows={rows ? rows : 1}
      />
    </div>
  );
}

export default FormField;
