import "./EllipsePercentMatch.scss";
import { useRef, useEffect } from "react";

const EllipsePercentMatch = ({ percentage }) => {
  // Base ellipse circumference calculation
  const rx = 23.5; // Half of the width
  const ry = 23.5; // Half of the height
  const baseCircumference = 2 * Math.PI * Math.sqrt((rx * rx + ry * ry) / 2);

  // Percentage indicator stroke calculation
  const indicatorLength = baseCircumference * (percentage / 100);

  // Rotate transform to start from the top
  const rotateTransform = `rotate(-90 ${rx + 1} ${ry + 1})`;

  /*Animations*/
  const pathRef = useRef(null);

  useEffect(() => {
    if (percentage > 0 && pathRef.current) {
      const path = pathRef.current;
      const length = baseCircumference;
      path.style.strokeDasharray = length;
      path.style.strokeDashoffset = length;

      // Animate stroke dash offset
      const finalDashOffset = length - length * (percentage / 100);
      const animationDuration = 3000; // 3 seconds

      const startTime = performance.now();

      function animate(time) {
        let progress = (time - startTime) / animationDuration;
        if (progress > 1) progress = 1;

        path.style.strokeDashoffset =
          length - progress * (length - finalDashOffset);

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      }

      requestAnimationFrame(animate);
    }
  }, [percentage, baseCircumference]);

  // Determine colors and stroke visibility based on percentage
  let ellipseStrokeColor = "#E9E9E9"; // Default color
  let showPercentageStroke = percentage > 0;
  let percentageStrokeColor = "#90815A";

  if (percentage >= 1 && percentage <= 49) {
    ellipseStrokeColor = "#EDEBE4";
    percentageStrokeColor = "#90815A";
  } else if (percentage >= 50 && percentage <= 74) {
    ellipseStrokeColor = "#F6F0E1";
    percentageStrokeColor = "#C8A243";
  } else if (percentage >= 75 && percentage <= 100) {
    ellipseStrokeColor = "#FFEEC5";
    percentageStrokeColor = "#FFC943";
  } else if (percentage === 100) {
    // Render a different SVG for 100%
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="78"
        height="78"
        viewBox="0 0 78 78"
        fill="none"
      >
        <path
          d="M39 3C43.7276 3 48.4089 3.93117 52.7766 5.74034C57.1443 7.54951 61.1129 10.2012 64.4558 13.5442C67.7988 16.8871 70.4505 20.8557 72.2597 25.2234C74.0688 29.5911 75 34.2724 75 39C75 43.7276 74.0688 48.4089 72.2597 52.7766C70.4505 57.1443 67.7987 61.1129 64.4558 64.4559C61.1129 67.7988 57.1443 70.4505 52.7766 72.2597C48.4089 74.0688 43.7276 75 39 75C34.2724 75 29.5911 74.0688 25.2234 72.2597C20.8557 70.4505 16.8871 67.7987 13.5441 64.4558C10.2012 61.1129 7.5495 57.1443 5.74033 52.7766C3.93116 48.4089 3 43.7276 3 39C3 34.2724 3.93117 29.5911 5.74034 25.2234C7.54952 20.8557 10.2013 16.887 13.5442 13.5441C16.8871 10.2012 20.8557 7.5495 25.2234 5.74033C29.5911 3.93116 34.2724 3 39 3L39 3Z"
          stroke="url(#paint0_linear_1695_2682)"
          strokeWidth="4.86486"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1695_2682"
            x1="12.0612"
            y1="75"
            x2="77.3143"
            y2="69.8337"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.197917" stop-color="#FFC943" />
            <stop offset="1" stop-color="#FF9D43" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      key={percentage}
    >
      {/* Base Ellipse */}
      <path
        d="M47 24.5C47 27.4547 46.418 30.3806 45.2873 33.1104C44.1566 35.8402 42.4992 38.3206 40.4099 40.4099C38.3206 42.4992 35.8402 44.1566 33.1104 45.2873C30.3806 46.418 27.4547 47 24.5 47C21.5453 47 18.6194 46.418 15.8896 45.2873C13.1598 44.1566 10.6794 42.4992 8.5901 40.4099C6.50078 38.3206 4.84344 35.8402 3.71271 33.1104C2.58198 30.3805 2 27.4547 2 24.5C2 21.5453 2.58198 18.6194 3.71271 15.8896C4.84344 13.1598 6.50078 10.6794 8.5901 8.5901C10.6794 6.50078 13.1598 4.84344 15.8896 3.71271C18.6195 2.58198 21.5453 2 24.5 2C27.4547 2 30.3806 2.58198 33.1104 3.71271C35.8402 4.84344 38.3206 6.50078 40.4099 8.5901C42.4992 10.6794 44.1566 13.1598 45.2873 15.8896C46.418 18.6195 47 21.5453 47 24.5L47 24.5Z"
        stroke={ellipseStrokeColor}
        strokeWidth="3.04054"
        strokeLinecap="round"
      />
      {/* Percentage Indicator Stroke */}
      {percentage > 0 && (
        <path
          d="M47 24.5C47 27.4547 46.418 30.3806 45.2873 33.1104C44.1566 35.8402 42.4992 38.3206 40.4099 40.4099C38.3206 42.4992 35.8402 44.1566 33.1104 45.2873C30.3806 46.418 27.4547 47 24.5 47C21.5453 47 18.6194 46.418 15.8896 45.2873C13.1598 44.1566 10.6794 42.4992 8.5901 40.4099C6.50078 38.3206 4.84344 35.8402 3.71271 33.1104C2.58198 30.3805 2 27.4547 2 24.5C2 21.5453 2.58198 18.6194 3.71271 15.8896C4.84344 13.1598 6.50078 10.6794 8.5901 8.5901C10.6794 6.50078 13.1598 4.84344 15.8896 3.71271C18.6195 2.58198 21.5453 2 24.5 2C27.4547 2 30.3806 2.58198 33.1104 3.71271C35.8402 4.84344 38.3206 6.50078 40.4099 8.5901C42.4992 10.6794 44.1566 13.1598 45.2873 15.8896C46.418 18.6195 47 21.5453 47 24.5L47 24.5Z"
          stroke={percentageStrokeColor}
          strokeWidth="3.04054"
          strokeLinecap="round"
          strokeDasharray={baseCircumference}
          strokeDashoffset={baseCircumference - indicatorLength}
          transform={rotateTransform}
          className="ellipse-percentage-stroke"
          ref={pathRef}
        />
      )}
    </svg>
  );
};

export default EllipsePercentMatch;
