import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { generateProfileService } from "../services/profile";
import { updateAnonymousUserService } from "../services/userEvent";
import {
  MATCHMAKER_UPDATED,
  PROFILE_GENERATED,
  NOT_AUTHENTICATED,
  NO_LAST_ACTION,
} from "../constants/lastActions";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //curr user information
  const [user, setUser] = useState("Anonymous");
  //matchmaker object - { profileQuiz, profileQuizMaeTrims  }
  const [matchmaker, setMatchmaker] = useState({});
  //Last action of user - used to update matchmaker for several pages
  const [lastAction, setLastAction] = useState(NO_LAST_ACTION);
  //loading state to prepare all the user information
  const [isAuthContextLoading, setIsAuthContextLoading] = useState(true);
  const [triggerUpdateAuthContext, setTriggerUpdateAuthContext] = useState(1);
  //comparison chart state information for tracking and updating
  const [currentComparisonChartState, setCurrentComparisonChartState] =
    useState([]);
  const [extraVehicles, setExtraVehicles] =
    useState([]);
  const [userAddedFeatures, setUserAddedFeatures] = useState([]);
  const [lastChartId, setLastChartId] = useState("");

  const printMatchmaker = () => {
    console.log("Matchmaker inside auth context", matchmaker);
  };

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsAuthContextLoading(true);
    console.log("Logout in auth context");
    localStorage.removeItem("anonymous-profile");
    setIsLoggedIn(false);
    setUser(NO_LAST_ACTION);
    setMatchmaker({});
    setIsAuthContextLoading(false);
    setLastAction(NO_LAST_ACTION);
    setTriggerUpdateAuthContext((prevCount) => prevCount + 1);
    setCurrentComparisonChartState({});
  };

  const updateAuthContext = async () => {
    setIsAuthContextLoading(true);
    try {
      const response = await fetch("/api/auth/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          anonymousId: localStorage.getItem("anonymous-id"),
        }),
        credentials: "include",
      });

      const auth_response = await response.json();
      console.log(auth_response);

      if (auth_response.anonymousId !== undefined) {
        localStorage.setItem("anonymous-id", auth_response.anonymousId);
      }

      if (response.ok) {
        console.log("Authenticated.");
        login();

        console.log("Mapping:", auth_response.anonymousUserToUserIdMapping);
        if (
          auth_response.anonymousUserToUserIdMapping === null ||
          auth_response.anonymousUserToUserIdMapping === undefined
        ) {
          console.log("Mapping anonymous user to the userId");
          //update the existing user tag
          updateAnonymousUserService({ userId: auth_response.user._id });
        }

        // only generate a new profile if an anonymous profile was generated  before
        // and the current logged in user does not have any profileQuizCars already
        // if they do routing will be handled by App.jsx logic
        const anonymous_profile = localStorage.getItem("anonymous-profile");
        if (
          anonymous_profile !== null &&
          auth_response.matchmaker?.profileQuizMaeTrims.length === 0
        ) {
          console.log("Will generate profile.");
          const anonymous_profile_json = JSON.parse(anonymous_profile);
          console.log("anony quiz data:", anonymous_profile_json.profileQuiz);

          const profile_response = await generateProfileService(
            anonymous_profile_json.profileQuiz
          );
          if (profile_response != null) {
            console.log("PROFILE FROM ANONY TO ACTUAL");
            setMatchmaker(profile_response.matchmaker);
            setUser(auth_response.user);
            setLastAction(PROFILE_GENERATED);
            localStorage.removeItem("anonymous-profile");
          }
        } else {
          let CCStatesLength =
            auth_response.user.comparisonChartUserStates.length;
          setMatchmaker(auth_response.matchmaker);
          setLastAction(MATCHMAKER_UPDATED);
          setUser(auth_response.user);
          setCurrentComparisonChartState(
            CCStatesLength > 0
              ? auth_response.user.comparisonChartUserStates.at(-1)
                  .maeTrimIdList
              : []
          );
          setLastChartId(
            CCStatesLength > 0
              ? auth_response.user.comparisonChartUserStates.at(-1)._id
              : ""
          );
          setExtraVehicles(auth_response.user.addedExtraCars);
          setUserAddedFeatures(auth_response.user.userAddedExtraFeatures);
        }
      } else {
        setLastAction(NOT_AUTHENTICATED);
        console.log(auth_response.error);
      }
    } catch (error) {
      console.log("Error:", error);
    }
    setIsAuthContextLoading(false);
  };

  //Only use for logged in users
  const updateMatchmaker = async (e) => {
    setIsAuthContextLoading(true);
    try {
      const response = await fetch("/api/auth/authenticate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          anonymousId: localStorage.getItem("anonymous-id"),
        }),
        credentials: "include",
      });

      const auth_response = await response.json();
      console.log(auth_response);

      if (auth_response.anonymousId !== undefined) {
        localStorage.setItem("anonymous-id", auth_response.anonymousId);
      }

      if (response.ok) {
        let CCStatesLength =
          auth_response.user.comparisonChartUserStates.length;
        setLastAction(MATCHMAKER_UPDATED);
        setMatchmaker(auth_response.matchmaker);
        setCurrentComparisonChartState(
          CCStatesLength > 0
            ? auth_response.user.comparisonChartUserStates.at(-1).maeTrimIdList
            : []
        );
        setLastChartId(
          CCStatesLength > 0
            ? auth_response.user.comparisonChartUserStates.at(-1)._id
            : ""
        );
        setExtraVehicles(auth_response.user.addedExtraCars);
        setUserAddedFeatures(auth_response.user.userAddedExtraFeatures);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
    setIsAuthContextLoading(false);
  };

  useEffect(() => {
    console.log("Update Auth Context", triggerUpdateAuthContext);
    updateAuthContext();
  }, [triggerUpdateAuthContext]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        matchmaker,
        lastAction,
        isAuthContextLoading,
        login,
        logout,
        updateAuthContext,
        setMatchmaker,
        setUser,
        printMatchmaker,
        setLastAction,
        setTriggerUpdateAuthContext,
        updateMatchmaker,
        currentComparisonChartState,
        setCurrentComparisonChartState,
        extraVehicles,
        setExtraVehicles,
        userAddedFeatures,
        setUserAddedFeatures,
        lastChartId,
        setLastChartId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
