import "./MdpFuelEconomy.scss";
import { useState } from "react";
import MdpButtonCard from "../mdpButtonCard/MdpButtonCard";
import fuelTankSizeImg from "../../assets/images/mdp-fuel-tank-size-img.svg";
import fuelConsumptionImg from "../../assets/images/mdp-fuel-consumption-img.svg";
import estimatedRangeImg from "../../assets/images/mdp-estimated-range-img.svg";
import tankOfGasImg from "../../assets/images/mdp-tank-of-gas-img.svg";
import costPerKmImg from "../../assets/images/mdp-cost-per-km-img.svg";

function MdpFuelEconomy({ vehicleDetails }) {
  // const [fuelDetails, setFuelDetails] = useState("");
  // const [legFirstRowDetails, setLegFirstRowDetails] = useState("");

  const [selectedImage, setSelectedImage] = useState(fuelTankSizeImg); // Default to the first image
  const [selectedTitle, setSelectedTitle] = useState("Fuel Tank Size");

  const handleSelect = (title, selectedImg) => {
    setSelectedTitle(title); // Update the selected title
    setSelectedImage(selectedImg); // Update the selected image
  };

  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  return (
    <div className="dimensions">
      <div className="dimensions__left">
        <img
          src={selectedImage}
          alt="Selected"
          className="dimensions__left--image"
        />
      </div>
      <div className="dimensions__right">
        <MdpButtonCard
          title="Fuel Tank Size"
          details="45L"
          isSelected={selectedTitle === "Fuel Tank Size"}
          onSelect={() => handleSelect("Fuel Tank Size", fuelTankSizeImg)}
        />
        <MdpButtonCard
          title="Fuel Consumption"
          details={
            vehicleDetails.calculatedMDPFeatures.fuelConsumption + " L / 100km"
          }
          isSelected={selectedTitle === "Fuel Consumption"}
          onSelect={() => handleSelect("Fuel Consumption", fuelConsumptionImg)}
        />
        <MdpButtonCard
          title="Estimated Range"
          details={
            vehicleDetails.calculatedMDPFeatures.estimatedRangeByTank +
            "km range / tank"
          }
          isSelected={selectedTitle === "Estimated Range"}
          onSelect={() => handleSelect("Estimated Range", estimatedRangeImg)}
        />
        <MdpButtonCard
          title="Average Tank of Gas at $1.90"
          details={
            "$" +
            formatPrice(vehicleDetails.calculatedMDPFeatures.priceOfFullTank)
          }
          isSelected={selectedTitle === "Average Tank of Gas"}
          onSelect={() => handleSelect("Average Tank of Gas", tankOfGasImg)}
        />
        <MdpButtonCard
          title="Cost per Kilometer"
          details={
            "$" + formatPrice(vehicleDetails.calculatedMDPFeatures.costPerKm)
          }
          isSelected={selectedTitle === "Cost per Kilometer"}
          onSelect={() => handleSelect("Cost per Kilometer", costPerKmImg)}
        />
      </div>
    </div>
  );
}

export default MdpFuelEconomy;
