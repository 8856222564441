import "../../styles/formStyles/formStyles.scss";
import { Link } from "react-router-dom";
import FormField from "../formField/FormField.jsx";
import ButtonPlain from "../buttonPlain/ButtonPlain.jsx";
import ButtonGradient from "../buttonGradient/ButtonGradient";
import googleIcon from "../../assets/icons/devicon_google.svg";
import { FormError } from "../formError/FormError.jsx";

function SignupForm({
  handleSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  signUpStatus,
  accountSignUpError,
  googleAuth,
}) {
  return (
    <div className="form-wrapper">
      <div className="form">
        <h2 className="form__title">{signUpStatus}</h2>
        <div className="login">
          <p className="login__text">Already have an account?</p>
          <Link to="/signin">
            <p className="login__text--bold">Sign In</p>
          </Link>
        </div>
        <div className="google-signup">
          <ButtonPlain
            className="button__plain--yellow"
            text="Sign Up With Google"
            onClick={googleAuth}
          >
            <img
              className="google-signup__icon"
              src={googleIcon}
              alt="google logo"
            />
          </ButtonPlain>
        </div>
        <div className="divider">
          <p className="divider__text">OR</p>
        </div>
        <form className="form-fields" onSubmit={handleSubmit}>
          <div className="form-fields__top">
            <FormField
              type="text"
              placeholder="First Name"
              id="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormField
              type="text"
              placeholder="Last Name"
              id="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <FormField
            type="email"
            id="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormField
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormError type={accountSignUpError} />
          <ButtonGradient type="submit" text="Sign Up" />
        </form>
      </div>
    </div>
  );
}

export default SignupForm;
