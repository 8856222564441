import success from "../../assets/images/success.svg";
import { useNavigate } from "react-router-dom";

const PasswordResetSuccess = () => {
  const Navigate = useNavigate();
  return (
    <>
      <div>
        <img src={success} alt="email-sent-image" />
      </div>
      <div
        style={{
          margin: 0,
        }}
        className="fp-wrapper__title"
      >
        Success!
      </div>
      <div className="fp-wrapper__description">
        <p
          style={{
            margin: 0,
          }}
        >
          Your password has been reset. Let’s get you back on the road to
          finding your perfect match!
        </p>
      </div>
      <button
        style={{
          padding: "10px 27px",
          borderRadius: "64px",
          border: "solid 1px black",
          backgroundColor: "white",
          marginTop: "19px",
        }}
        className="fp-wrapper__login-btn"
        onClick={() => {
          Navigate("/signin");
        }}
      >
        Login
      </button>
    </>
  );
};

export default PasswordResetSuccess;
