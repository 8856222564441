import "./MdpTrimLevels.scss";
import recommendedIcon from "../../assets/icons/mdp-recommended.svg";
import { useAuthContext } from "../../contexts/authContext";
import { useParams } from "react-router-dom";

function MdpTrimLevels({ vehicleDetails }) {
  const { matchmaker } = useAuthContext();

  const { maeTrimId } = useParams();

  const { orderedTrims } = vehicleDetails.calculatedMDPFeatures;

  console.log(orderedTrims);

  let recommendedTrim = orderedTrims.find(
    (orderedTrim) => orderedTrim._maeTrimId == maeTrimId
  );

  recommendedTrim = recommendedIcon.styleDescription;

  return (
    <div className="trim">
      <div className="trim__header">
        <h4 className="trim__header--title">TRIM LEVELS FOR YOU</h4>
        <p className="trim__header--text">Lowest to Highest</p>
      </div>
      <div className="trim-main">
        {orderedTrims.map((trim, index) => (
          <div
            className={`trim-main__card ${
              trim._maeTrimId == maeTrimId ? "recommended-level" : ""
            }`}
            key={index}
          >
            {trim._maeTrimId == maeTrimId && (
              <img
                src={recommendedIcon}
                alt="Recommended"
                className="recommended-icon"
              />
            )}
            <h4 className="trim-main__card--title">{trim.styleDescription}</h4>
            <p className="trim-main__card--price">${trim.baseMSRP}</p>
            <p className="trim-main__card--text">starting MSRP</p>
          </div>
        ))}
      </div>
      <div className="recommendation">
        <img src={recommendedIcon} alt="" />
        <p className="recommendation__text">
          We are recommending the{" "}
          <span className="bold">{recommendedTrim}</span> trim because it is the
          best trim that matches your needs and gives you the best bang for your
          buck.
        </p>
      </div>
    </div>
  );
}

export default MdpTrimLevels;
