import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../assets/images/User-Icon.svg";
import UpdatePopup from "../updatePopup/updatePopup";
import "./accountEdit.scss";
import { useAuthContext } from "../../contexts/authContext";
import { ButtonSpinLoader } from "../buttonSpinLoader/buttonSpinLoader";

export const AccountEdit = () => {
  const { user, setUser, logout } = useAuthContext();
  const { googleProfileImage } = user;
  const updatePasswordText = "Password Reset";
  const updatePasswordTextSub = "Your new password has been saved!";
  const [firstname, setFirstName] = useState(user.firstName);
  const [lastname, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [npassword, setNpassword] = useState("");
  const [opassword, setOpassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [detailsSaveBtn, setDetailsSaveBtn] = useState(false);
  const [passSaveBtn, setPassSaveBtn] = useState(false);
  const [disabledInfo, setDisabledInfo] = useState(true);
  const [resetBtnDisabled, setResetBtnDisabled] = useState(true);
  const [PopupShow, setPopupShow] = useState(false);
  const [validPasswordChange, setValidPasswordChange] = useState(true);
  const Navigate = useNavigate();

  const updateUserMainInfo = async (e) => {
    const btnSpinner = document.getElementById("btn-spin-loader");
    btnSpinner.className = "show-spin-loader";
    try {
      const response = await fetch("/api/account/editaccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
        body: JSON.stringify({
          firstName: firstname,
          lastName: lastname,
          email: email,
        }),
      });
      if (response.ok) {
        const res = await response.json();
        setDetailsSaveBtn(false);
        setDisabledInfo(true);
        user.firstName = firstname;
        user.lastName = lastname;
        user.email = email;
        console.log(res);
        setUser(user);
      } else {
        const errorData = await response.json();
        console.log(errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
    btnSpinner.className = "hide-spin-loader";
  };

  const resetUserPassword = async (passwordInfo) => {
    const btnSpinner = document.getElementById("btn-spin-loader");
    btnSpinner.className = "show-spin-loader";
    try {
      const response = await fetch("/api/account/resetpassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
        body: JSON.stringify({
          newPassword: passwordInfo.new,
          oldPassword: passwordInfo.old,
          confirmedPassword: passwordInfo.confirmed,
        }),
      });
      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setPassSaveBtn(false);
        setPopupShow(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        const errorData = await response.json();
        console.log(errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
    btnSpinner.className = "hide-spin-loader";
  };

  const logOutFunctionality = async () => {
    console.log("logout clicked");
    try {
      const response = await fetch("/api/auth/logout", {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        credentials: "include",
      });

      console.log("response received to logout request");

      if (response.ok) {
        console.log(response);
        logout();
        localStorage.removeItem("disliked");
        localStorage.removeItem("liked");
        Navigate("/");
      } else {
        const errorData = await response.json();
        console.log(errorData.error);
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  function passwordValidator(ev) {
    if (
      ev.target.value.length > 0 &&
      npassword.length > 0 &&
      ev.target.value === npassword
    ) {
      setValidPasswordChange(false);
    }
    setCpassword(ev.target.value);
  }

  return (
    <>
      {PopupShow ? (
        <>
          <div className="popup_position">
            <UpdatePopup
              Popupshow={setPopupShow}
              main={updatePasswordText}
              sub={updatePasswordTextSub}
            />
          </div>
        </>
      ) : null}

      <div className="accountEdit">
        <div className="accountEdit__info">
          <div className="accountEdit__profile">
            <img
              src={googleProfileImage ? googleProfileImage : UserIcon}
              alt="User Icon"
              className="accountEdit__profile--img"
            />
          </div>
          <div className="accountEdit__forms">
            <div className="accountEdit__forms--details">
              <div className="accountEdit__forms--outsideinputContainers">
                <div className="accountEdit__forms--deepinputContainers">
                  <span
                    className="w-50 label-design"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    First Name
                  </span>
                  <span
                    className="w-50 label-design"
                    style={{
                      display: "inline-block",
                    }}
                  >
                    Last Name
                  </span>
                </div>
                <div className="accountEdit__forms--insideinputcontainers">
                  <input
                    disabled={disabledInfo}
                    className="profile-input"
                    style={{
                      margin: "0.5rem 0.5rem 1rem 0rem",
                    }}
                    type="text"
                    name="firstname"
                    value={firstname}
                    onChange={(ev) => setFirstName(ev.target.value)}
                  />
                  <input
                    disabled={disabledInfo}
                    className="profile-input"
                    style={{
                      margin: "0.5rem 0.5rem 1rem 0rem",
                    }}
                    type="text"
                    name="lastname"
                    value={lastname}
                    onChange={(ev) => setLastName(ev.target.value)}
                  />
                </div>
              </div>
              <div
                className="accountEdit__forms--outsideinputContainers"
                style={{
                  marginBlock: "1rem",
                }}
              >
                <span
                  className="label-design"
                  style={{
                    display: "inline-block",
                  }}
                >
                  Email Address
                </span>
                <input
                  disabled={disabledInfo}
                  className="profile-input"
                  style={{
                    margin: "0.5rem 0rem",
                    width: "95%",
                  }}
                  type="email"
                  name="email"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                />
              </div>
              <div className="button-container">
                {!detailsSaveBtn ? (
                  <>
                    <div className="cta-btns-group">
                      {!user?.googleId ? (
                        <button
                          className="cta-btns"
                          onClick={() => {
                            setDetailsSaveBtn(true);
                            setDisabledInfo(false);
                          }}
                        >
                          Edit Account Info
                        </button>
                      ) : null}{" "}
                      <button
                        className="cta-btns"
                        onClick={() => logOutFunctionality()}
                      >
                        Log Out
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="cta-btns-group">
                      <button
                        className="cta-btns"
                        onClick={() => {
                          setDetailsSaveBtn(false);
                          setDisabledInfo(true);
                        }}
                      >
                        Cancel
                      </button>{" "}
                      <button
                        className="cta-btns loader-included background-color-save"
                        type="submit"
                        onClick={() => {
                          updateUserMainInfo();
                        }}
                      >
                        <ButtonSpinLoader />
                        Save Changes
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!user?.googleId && (
              <div className="accountEdit__forms--details flex-gap">
                <h4
                  className="label-design"
                  style={{
                    margin: 0,
                  }}
                >
                  Password
                </h4>
                {passSaveBtn ? (
                  <>
                    <div
                      className="accountEdit__forms--outsideinputContainers"
                      style={{
                        gap: "0.75rem",
                      }}
                    >
                      <div className="password-container w-60">
                        <label className="password-label">Old Password</label>
                        <div className="pass-input-box">
                          <input
                            className="password-input"
                            autoComplete={false}
                            disabled={resetBtnDisabled}
                            type="password"
                            name="opassword"
                            value={opassword}
                            onChange={(ev) => setOpassword(ev.target.value)}
                          />
                        </div>
                      </div>
                      <div className="password-container w-60">
                        <label className="password-label">New Password</label>
                        <div className="pass-input-box">
                          <input
                            className="password-input"
                            type="password"
                            autoComplete={false}
                            disabled={resetBtnDisabled}
                            name="npassword"
                            value={npassword}
                            onChange={(ev) => setNpassword(ev.target.value)}
                          />
                        </div>
                      </div>
                      <div className="password-container w-60">
                        <label className="password-label">
                          Confirm New Password
                        </label>
                        <div className="pass-input-box">
                          <input
                            className="password-input"
                            type="password"
                            autoComplete={false}
                            disabled={resetBtnDisabled}
                            name="cpassword"
                            value={cpassword}
                            onChange={(ev) => {
                              passwordValidator(ev);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div>
                  <div>
                    {!passSaveBtn ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.75rem",
                          }}
                        >
                          <button
                            className="cta-btns"
                            style={{
                              margin: 0,
                            }}
                            onClick={() => {
                              setPassSaveBtn(true);
                              setResetBtnDisabled(false);
                            }}
                          >
                            Reset Password
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.75rem",
                          }}
                        >
                          <button
                            className="cta-btns"
                            onClick={() => {
                              setPassSaveBtn(false);
                              setResetBtnDisabled(true);
                            }}
                          >
                            Cancel
                          </button>{" "}
                          <button
                            className={`cta-btns loader-included background-color-save ${
                              validPasswordChange ? "disabled" : "enabled"
                            }`}
                            disabled={validPasswordChange}
                            onClick={() => {
                              resetUserPassword({
                                new: npassword,
                                old: opassword,
                                confirmed: cpassword,
                              });
                              setNpassword("");
                              setCpassword("");
                              setOpassword("");
                            }}
                          >
                            <ButtonSpinLoader width="9px" height="9px" />
                            Save Password
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
