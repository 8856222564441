import "./SpeedDatingQuiz.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import DashboardSpeedDatingQuiz from "../../components/dashboardSpeedDatingQuiz/DashboardSpeedDatingQuiz";

const SpeedDatingQuiz = () => {
  return (
    <>
      <Sidebar
      // isLoggedIn={isLoggedIn}
      // toggleLogin={toggleLogin}
      // setUser={setUser}
      // user={user}
      />
      <DashboardSpeedDatingQuiz />
    </>
  );
};

export default SpeedDatingQuiz;
