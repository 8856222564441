import "./MdpCompatibility.scss";
import requirementsPositive from "../../assets/icons/mdp-requirements-yes.svg";
import requirementsNegative from "../../assets/icons/mdp-requirements-no.svg";
import { useAuthContext } from "../../contexts/authContext";
import { useParams } from "react-router-dom";
import { CalculateAnalysis } from "../../utils/calculateAnalysis";

function MdpCompatibility({ vehicleDetails }) {
  const { matchmaker } = useAuthContext();

  const { maeTrimId } = useParams();

  console.log("liked Mae Trim with compatibilityInfo", vehicleDetails);

  const orderedFeatures = [
    ...vehicleDetails.matchedFeatures,
    ...vehicleDetails.totalFeatures.filter(
      (feature) => !vehicleDetails.matchedFeatures.includes(feature)
    ),
  ];

  const analysisInfo = CalculateAnalysis(matchmaker.profileQuiz);
  console.log(analysisInfo);

  function formatCapitalisation(str) {
    return str
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^./, (match) => match.toUpperCase());
  }

  return (
    <div className="compatibility">
      <div className="compatibility__left">
        <div className="requirements">
          <div className="section">
            <h4 className="section__title">REQUIREMENTS</h4>
            <p className="section__text number">8/8</p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">{analysisInfo.Type}</p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">
              {analysisInfo.Transmission} transmission
            </p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">
              {analysisInfo.Engine}{" "}
              {analysisInfo.Engine.includes(",") ? "engines" : "engine"}
            </p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">Matches Cargo Space</p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text"> {analysisInfo["Price Range"]}</p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">{analysisInfo["Fuel Efficiency"]}</p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">{analysisInfo["Drive Train"]}</p>
          </div>
          <div className="section">
            <img className="section__icon" src={requirementsPositive} alt="" />
            <p className="section__text">{analysisInfo["Features"]}</p>
          </div>
        </div>
      </div>
      <div className="compatibility__right">
        <div className="desired-features">
          {" "}
          <div className="section">
            <h4 className="section__title">DESIRED FEATURES</h4>
            <p className="section__text number">
              {vehicleDetails.matchedFeatures.length}/
              {vehicleDetails.totalFeatures.length}
            </p>
          </div>
          {orderedFeatures.map((feature, index) => (
            <div className="section" key={index}>
              <img
                className="section__icon"
                src={
                  vehicleDetails.matchedFeatures.includes(feature)
                    ? requirementsPositive
                    : requirementsNegative
                }
                alt=""
              />
              <p className="section__text">{formatCapitalisation(feature)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MdpCompatibility;
