import "./Dashboard404.scss";
import { Link } from "react-router-dom";
import notFoundImg from "../../assets/images/NotFound.svg";
import ButtonPlain from "../buttonPlain/ButtonPlain";
import { useAuthContext } from "../../contexts/authContext";

function Dashboard404() {
  const { isLoggedIn } = useAuthContext();
  return (
    <div className="page404">
      <div className="page404__intro">
        <div className="page404__intro--titles">
          <h2 className="header">Oops!</h2>
          <h1 className="subheader">Looks like you went off roading</h1>
        </div>
        <div className="page404__intro--description">
          <p className="page404__description">
            Let’s get you back on the right path! Please return to the homepage
            to find what you’re looking for or enjoy a different link from our
            sidebar.
          </p>
        </div>
        {!isLoggedIn ? (
          <div className="page404__intro--button">
            <Link to="/">
              <ButtonPlain
                className="button__plain--narrow"
                text="Return Home"
              />
            </Link>
          </div>
        ) : null}
      </div>
      <p className="page404__support">
        Need some support? Email our team at{" "}
        <a href="mailto:mae@makingautoeasy.com">mae@makingautoeasy.com</a>.
      </p>
      <div className="page404__filler">
        <img className="page404__filler--image" src={notFoundImg} alt=""></img>
      </div>
    </div>
  );
}

export default Dashboard404;
