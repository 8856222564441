import emailSent from "../../assets/images/emailSent.svg";

const EmailSent = () => {
  return (
    <>
      <div>
        <img src={emailSent} alt="email-sent-image" />
      </div>
      <div
        style={{
          margin: 0,
        }}
        className="fp-wrapper__title"
      >
        Email Sent
      </div>
      <div className="fp-wrapper__description">
        <p
          style={{
            margin: 0,
          }}
        >
          Please check your inbox to reset your password! Don’t worry, we’ll get
          you back to your matches in no time.
        </p>
      </div>
    </>
  );
};

export default EmailSent;
