// import "./QuizOptionCard.scss";
// import spaceGrey from "../../assets/icons/quiz-space-grey.svg";
// import spaceWhite from "../../assets/icons/quiz-space-white.svg";
// import yellowCheckMark from "../../assets/icons/quiz-check-yellow.svg";
// import { useEffect, useState } from "react";

// function QuizOptionCard({
// 	text,
// 	whiteicon,
// 	greyicon,
// 	subtext,
// 	selectOption,
// 	unselectOption,
// 	selected,
// 	className = "card__unselected",
// 	index,
// }) {
// 	const [isSelected, setIsSelected] = useState(selected);

// 	// console.log("greyicon", greyicon);

// 	const handleSelect = () => {
// 		setIsSelected(!isSelected);
// 	};

// 	console.log(text, isSelected);
// 	useEffect(() => {
// 		if (isSelected) {
// 			selectOption(text, index);
// 		} else {
// 			unselectOption(text, index);
// 		}
// 	}, [isSelected]);

// 	return (
// 		<div
// 			// className={`card ${isSelected ? "card__selected" : "card__unselected"} ${
// 			// 	subtext == "" ? "" : "card__long"
// 			// }`}
// 			className={`card ${isSelected ? "card__selected" : "card__unselected"} ${
// 				subtext == "" ? "" : "card__long"
// 			} ${className}`}
// 			onClick={handleSelect}
// 		>
// 			<div
// 				className={`card__images ${
// 					className !== "card__unselected" ? "feature-card__images" : ""
// 				}`}
// 			>
// 				<img
// 					className={`card__images ${
// 						className !== "card__unselected" ? "feature-card__images--icon" : ""
// 					}`}
// 					src={require(`../../assets/icons/${greyicon}`)}
// 					// src={
// 					//   isSelected
// 					//     ? require(`../../assets/icons/${whiteicon}`)
// 					//     : require(`../../assets/icons/${greyicon}`)
// 					// }
// 					alt={text}
// 				></img>
// 				{/* {isSelected ? (
// 					<img className="card__images--check" src={yellowCheckMark} alt="" />
// 				) : null} */}
// 			</div>
// 			<div
// 				className={`card__images ${
// 					className !== "card__unselected" ? "feature-card__text" : ""
// 				}`}
// 			>
// 				{text}
// 			</div>
// 			<div className="card--subtext">{subtext}</div>
// 		</div>
// 	);
// }

// export default QuizOptionCard;

import "./QuizOptionCard.scss";
import { useEffect, useState } from "react";

function QuizOptionCard({
  text,
  greyicon,
  subtext,
  selectOption,
  unselectOption,
  selected,
  className = "card__unselected",
  index,
}) {
  const [isSelected, setIsSelected] = useState(selected);

  const handleSelect = () => {
    if (!isSelected) {
      selectOption(index);
    } else {
      unselectOption(index);
    }
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  // Conditional import for the image to avoid breaking if greyicon is not provided
  const iconSrc = greyicon ? require(`../../assets/icons/${greyicon}`) : "";

  return (
    <div
      className={`card ${isSelected ? "card__selected" : "card__unselected"} ${
        subtext === "" ? "" : "card__long"
      } ${className}`}
      onClick={handleSelect}
    >
      <div
        className={`card__images ${
          className === "feature-card" ? "feature-card__images" : ""
        }`}
      >
        <img
          className={`card__images--icon ${
            className === "feature-card" ? "feature-card__images--icon" : ""
          }`}
          src={iconSrc}
          alt=""
        ></img>
      </div>
      <div
        className={`card--text ${
          className === "feature-card" ? "feature-card--text" : ""
        }`}
      >
        {text}
      </div>
      <div className="card--subtext">{subtext}</div>
    </div>
  );
}

export default QuizOptionCard;
