import "./MdpPricingTransparency.scss";

function MdpPricingTransparency({ vehicleDetails }) {
  function formatPrice(value) {
    return new Intl.NumberFormat("en-US").format(value);
  }

  const freightHandling = 2000;
  const acFee = 100;
  const tireLevy = 20;
  const documentationFee = 600;

  // Calculate the total cost
  const totalCost =
    vehicleDetails.calculatedMDPFeatures.baseMSRP +
    freightHandling +
    acFee +
    tireLevy +
    documentationFee;

  return (
    <div className="mdp-pricing">
      <h4 className="mdp-pricing__title">Estimated Costs</h4>
      <div className="mdp-table">
        <div className="mdp-table__row">
          <p className="mdp-table__row--text">MSRP</p>
          <p className="mdp-table__row--text">
            ${formatPrice(vehicleDetails.calculatedMDPFeatures.baseMSRP)}
          </p>
        </div>
        <div className="mdp-table__row">
          <p className="mdp-table__row--text">Freight/Handling</p>
          <p className="mdp-table__row--price">
            ${formatPrice(freightHandling)}
          </p>
        </div>
        <div className="mdp-table__row">
          <p className="mdp-table__row--text">AC Fee</p>
          <p className="mdp-table__row--price">${formatPrice(acFee)}</p>
        </div>
        <div className="mdp-table__row">
          <p className="mdp-table__row--text">Tire Levy</p>
          <p className="mdp-table__row--price">${formatPrice(tireLevy)}</p>
        </div>
        <div className="mdp-table__row">
          <p className="mdp-table__row--text">Documentation Fee</p>
          <p className="mdp-table__row--price">
            ${formatPrice(documentationFee)}
          </p>
        </div>
        <div className="mdp-table__row">
          <p className="mdp-table__row--bold">Total</p>
          <p className="mdp-table__row--bold">${formatPrice(totalCost)}</p>
        </div>
      </div>
    </div>
  );
}

export default MdpPricingTransparency;
