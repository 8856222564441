import "../../styles/formStyles/formStyles.scss";
import { useNavigate, Link } from "react-router-dom";
import FormField from "../formField/FormField.jsx";
import ButtonPlain from "../buttonPlain/ButtonPlain.jsx";
import ButtonGradient from "../buttonGradient/ButtonGradient";
import googleIcon from "../../assets/icons/devicon_google.svg";
import { FormError } from "../formError/FormError.jsx";

const SigninForm = ({
  handleSubmit,
  email,
  setEmail,
  password,
  setPassword,
  accountSignInError,
  googleAuth,
}) => {
  const Navigate = useNavigate();

  return (
    <div className="form-wrapper">
      <div className="form">
        <h2 className="form__title">Welcome Back!</h2>
        <div className="login">
          <p className="login__text">Don’t have an account?</p>
          <Link to="/signup">
            <p className="login__text--bold">Sign Up</p>
          </Link>
        </div>
        <div className="google-signup">
          <ButtonPlain
            className="button__plain--yellow"
            text="Sign In With Google"
            onClick={googleAuth}
          >
            <img
              className="google-signup__icon"
              src={googleIcon}
              alt="google logo"
            />
          </ButtonPlain>
        </div>
        <div className="divider">
          <p className="divider__text">OR</p>
        </div>
        <form className="form-fields" onSubmit={handleSubmit}>
          <FormField
            type="email"
            id="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormField
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormError type={accountSignInError} />
          <ButtonGradient type="submit" text="Login" />
          <p
            className="forgot-password-section"
            onClick={() => Navigate("/forgot-password")}
          >
            Forgot Password
          </p>
        </form>
      </div>
    </div>
  );
};

export default SigninForm;
