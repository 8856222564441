import React from "react";
import "./banner.scss";
import ButtonGradient from "../buttonGradient/ButtonGradient";

const Banner = ({ onClick, heading, text, buttonText }) => {
  return (
    <div className="banner-container">
      <div className="banner-container--text">
        {heading ? <h3 style={{marginBottom:"1rem"}}>{heading}</h3> : null}
        <p>{text}</p>
      </div>
      <div className="banner-container--button" onClick={onClick}>
      <ButtonGradient text={buttonText}  />
      </div>
    </div>
  );
};

export default Banner;
