import "./matchAnalysisSettings.scss";
import { useState, useEffect } from "react";
import carTypeIcon from "../../assets/icons/match-analysis-type.svg";
import transmissionIcon from "../../assets/icons/match-analysis-transmission.svg";
import engineIcon from "../../assets/icons/match-analysis-engine.svg";
import driveTrainIcon from "../../assets/icons/match-analysis-drive-train.svg";
import cargoSpaceIcon from "../../assets/icons/match-analysis-cargo-space.svg";
import featuresIcon from "../../assets/icons/match-analysis-features.svg";
import fuelIcon from "../../assets/icons/match-analysis-fuel.svg";
import priceRangeIcon from "../../assets/icons/match-analysis-price.svg";
import { CalculateAnalysis } from "../../utils/calculateAnalysis";
import { useAuthContext } from "../../contexts/authContext";

function MatchAnalysisSettings() {
  const { matchmaker, printMatchmaker, user } = useAuthContext();
  const questionsResponse = matchmaker.profileQuiz;

  printMatchmaker();
  const [AnalysisInfo, setAnalysisInfo] = useState("");
  const [userLastQuizDate, setUserLastQuizDate] = useState(
    user?.lastUpdatedQuizAnswers
  );

  useEffect(() => {
    if (userLastQuizDate) {
      formatDate();
    }
  }, [user]);

  useEffect(() => {
    const newAnalysisInfo = CalculateAnalysis(questionsResponse);
    setAnalysisInfo(newAnalysisInfo);
  }, [matchmaker]);

  function formatDate() {
    const timestamp = user?.lastUpdatedQuizAnswers;
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    console.log(formattedDate);
    setUserLastQuizDate(formattedDate);
  }

  return (
    <div className="match-analysis-container">
      <div className="font-main font-styles-updated-text">
        <p>
          {userLastQuizDate &&
            `Based on your profile settings last updated on ${userLastQuizDate}`}
        </p>
      </div>
      <div className="matchanalysis mg-bt-end">
        <h2 className="matchanalysis__header font-main">Build</h2>
        <div className="matchanalysis__table bg-grey">
          <div className="feature">
            <img className="feature__img" src={carTypeIcon} />
            <p className="feature__text font-main">Type</p>
          </div>
          <div className="selection">
            <p className="selection__texts font-main">{AnalysisInfo["Type"]}</p>
          </div>
        </div>
        {/* <div className="matchanalysis__table">
					<div className="feature">
						<img className="feature__img" src={transmissionIcon} />
						<p className="feature__text font-main">TRANSMISSION</p>
					</div>
					<div className="selection">
						<p className="selection__texts font-main">Automatic</p>
					</div>
				</div> */}
        <div className="matchanalysis__table bg-white">
          <div className="feature">
            <img className="feature__img" src={engineIcon} />
            <p className="feature__text font-main">ENGINE</p>
          </div>
          <div className="selection">
            <p className="selection__texts font-main">
              {AnalysisInfo["Engine"]}
            </p>
          </div>
        </div>{" "}
        <div className="matchanalysis__table bg-grey">
          <div className="feature">
            <img className="feature__img" src={driveTrainIcon} />
            <p className="feature__text font-main">DRIVE TRAIN</p>
          </div>
          <div className="selection">
            <p className="selection__texts font-main">
              {AnalysisInfo["Drive Train"]}
            </p>
          </div>
        </div>
      </div>
      <div className="matchanalysis">
        <h2 className="matchanalysis__header font-main">Traits</h2>
        <div className="matchanalysis__table bg-grey">
          <div className="feature">
            <img className="feature__img" src={cargoSpaceIcon} />
            <p className="feature__text font-main">CARGO SPACE</p>
          </div>
          <div className="selection">
            <p className="selection__texts font-main">
              {AnalysisInfo["Cargo Space"]}
            </p>
          </div>
        </div>
        <div className="matchanalysis__table bg-white">
          <div className="feature">
            <img className="feature__img" src={featuresIcon} />
            <p className="feature__text font-main">FEATURES</p>
          </div>
          <div className="selection">
            <p className="selection__texts font-main">
              {AnalysisInfo["Features"]}
            </p>
          </div>
        </div>{" "}
        {/* <div className="matchanalysis__table bg-white">
					<div className="feature">
						<img className="feature__img" src={fuelIcon} />
						<p className="feature__text font-main">FUEL EFFICIENCY</p>
					</div>
					<div className="selection">
						<p className="selection__texts font-main">City Zipper</p>
					</div>
				</div>{" "} */}
        <div className="matchanalysis__table bg-grey">
          <div className="feature">
            <img className="feature__img" src={priceRangeIcon} />
            <p className="feature__text font-main">PRICE RANGE</p>
          </div>
          <div className="selection">
            <p className="selection__texts font-main">
              {AnalysisInfo["Price Range"]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchAnalysisSettings;
