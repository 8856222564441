import "../../styles/formStyles/formStyles.scss";
import "./ForgotPassword.scss";
import React, { useState } from "react";
import ForgotPasswordForms from "../../components/forgotPasswordForms/forgotPasswordForms";
import PasswordResetForm from "../../components/passwordResetForm/passwordResetForm";
import EmailSent from "../../components/emailSent/emailSent";
import PasswordResetSuccess from "../../components/passwordResetSuccess/passwordResetSuccess";
import MaeCoreLogoYellow from "../../assets/images/MaeCoreLogoYellow.png";

const ForgotPassword = ({ show }) => {
  const [emailForm, setEmailForm] = useState(true);
  const [resetPasswordForm, setResetPasswordForm] = useState(true);
  return (
    <>
      {show === "email" ? (
        <div className="forgot-password-page">
          <div className="fp-wrapper">
            {emailForm ? (
              <ForgotPasswordForms
                showEmailForm={emailForm}
                setEmailForm={setEmailForm}
              />
            ) : (
              <EmailSent />
            )}
          </div>
        </div>
      ) : (
        <div className="forgot-password-page">
          <div className="fp-wrapper">
            {resetPasswordForm ? (
              <PasswordResetForm setResetPasswordForm={setResetPasswordForm} />
            ) : (
              <PasswordResetSuccess />
            )}
          </div>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          top: "36px",
          left: "38px",
        }}
      >
        <img src={MaeCoreLogoYellow} alt="LogoText" />
      </div>
    </>
  );
};

export default ForgotPassword;

