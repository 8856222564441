import { useState, useEffect } from "react";
import "./SidebarMenuItem.scss";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import { useParams, useLocation } from "react-router-dom";
import crownIcon from "../../assets/images/crown.png";

function SidebarMenuItem({ icon, label, to, isSelected, onClick }) {
  const { matchmaker } = useAuthContext();
  const [newMatchesUpdate, setNewMatchesUpdate] = useState(false);
  const [sendTo, setSendTo] = useState(to);
  const location = useLocation();
  useEffect(() => {
    const carsToDate = matchmaker?.profileQuizMaeTrims.filter((cars) => {
      return !cars.liked && !cars.disliked;
    });
    if (!matchmaker) return;
    if (
      matchmaker?.profileQuizMaeTrims?.length > 0 &&
      carsToDate &&
      carsToDate.length > 0
    ) {
      setNewMatchesUpdate(true);
      if (to === "/speed-dating") {
        setSendTo("/speed-dating/quiz");
      }
    } else {
      setNewMatchesUpdate(false);
    }
  }, [matchmaker]);
  const itemClass = `sidebar-menu-item ${isSelected ? "sidebar-selected" : ""}`;

  const isExternal = to.startsWith("http://") || to.startsWith("https://");

  const content = (
    <div className={itemClass}>
      {icon ? (
        <img className="sidebar-menu-item__icon" src={icon} alt={label} />
      ) : null}
      <span className="sidebar-menu-item__label">{label}</span>
      {newMatchesUpdate &&
      label === "Speed Dating" &&
      location.pathname !== "/speed-dating" ? (
        <span className="sidebar-menu-item__unviewed"></span>
      ) : null}
      {label === "Compare" && (
        <img className="crown-size" src={crownIcon} alt="premium" />
      )}
    </div>
  );

  // Render for external links
  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" onClick={onClick}>
        {content}
      </a>
    );
  }

  // Render for internal links
  return (
    <Link to={sendTo} onClick={onClick}>
      {content}
    </Link>
  );
}

export default SidebarMenuItem;
