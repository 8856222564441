import error from "../../assets/images/error.svg";

export function FormError({ type }) {
  let text = "";

  switch (type) {
    case "user":
      text = "OOPS! Cannot Find this Account";
      break;

    case "password":
      text = "Please Enter Correct Password";
      break;

    case "empty":
      text = "Empty Field";
      break;

    case "email":
      text = "Email Already Exists!";
      break;

    case "":
      text = "";
      break;

    default:
      text = "Some Error in Authorizing you";
      break;
  }

  return (
    text && (
      <div style={{ display: "flex", gap: "4px", marginBlockEnd: "4px" }}>
        <img src={error} alt="error" />
        <span className="account-error">{text}</span>
      </div>
    )
  );
}
