import { useState, useContext } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { AccountEdit } from "../../components/accountsEdit/accountEdit";
import { ProfileSettings } from "../../components/profileSettings/profileSettings";
import MatchAnalysisSettings from "../../components/matchAnalysisSettings/matchAnalysisSettings";
import "./Profile.scss";

function MyProfile() {
  const [selectedHeader, setSelectedHeader] = useState("2");
  return (
    <div className="account--page--layout">
      <Sidebar />
      <div className="MyProfileLayout">
        <div className="myprofile">
          <h3 className="myprofile__title">Profile</h3>
          <div className="myprofile__sel">
            <span
              style={{
                padding: "10px 10px 0px 10px",
              }}
            >
              {" "}
              <h4
                onClick={() => {
                  setSelectedHeader("1");
                }}
                className={
                  selectedHeader !== "1"
                    ? "myprofile__sel--header"
                    : "myprofile__sel--header selected-header"
                }
              >
                Accounts Settings
              </h4>
            </span>
            <span
              style={{
                padding: "10px 10px 0px 10px",
              }}
            >
              {" "}
              <h4
                onClick={() => {
                  setSelectedHeader("2");
                }}
                className={
                  selectedHeader !== "2"
                    ? "myprofile__sel--header"
                    : "myprofile__sel--header selected-header"
                }
              >
                Profile Settings
              </h4>
            </span>
            <span
              style={{
                padding: "10px 10px 0px 10px",
              }}
            >
              {" "}
              <h4
                onClick={() => {
                  setSelectedHeader("3");
                }}
                className={
                  selectedHeader !== "3"
                    ? "myprofile__sel--header"
                    : "myprofile__sel--header selected-header"
                }
              >
                Analysis
              </h4>
            </span>
          </div>
          {selectedHeader === "1" ? (
            <AccountEdit />
          ) : selectedHeader === "2" ? (
            <ProfileSettings />
          ) : (
            <MatchAnalysisSettings />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
