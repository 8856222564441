import "./MdpPerformance.scss";
import { useState } from "react";
import MdpButtonCard from "../mdpButtonCard/MdpButtonCard";
import horsepowerImg from "../../assets/images/mdp-horsepower-img.svg";
import torqueImg from "../../assets/images/mdp-torque-img.svg";
import { formatToZeroDecimal } from "../../utils/formatUtils.jsx";

function MdpPerformance({ vehicleDetails }) {
  const [selectedImage, setSelectedImage] = useState(horsepowerImg); // Default to the first image
  const [selectedTitle, setSelectedTitle] = useState("Horsepower");

  const handleSelect = (title, selectedImg) => {
    setSelectedTitle(title); // Update the selected title
    setSelectedImage(selectedImg); // Update the selected image
  };

  const getFormattedTorque = () => {
    const torqueString = vehicleDetails?.calculatedMDPFeatures?.torque;
    if (torqueString) {
      const torqueNumber = parseFloat(torqueString);
      return formatToZeroDecimal(torqueNumber) + " lb-ft";
    }
    return "N/A";
  };

  const formattedTorque = getFormattedTorque();

  return (
    <div className="dimensions">
      <div className="dimensions__left">
        <img
          src={selectedImage}
          alt="Selected"
          className="dimensions__left--image"
        />
      </div>
      <div className="dimensions__right">
        <MdpButtonCard
          title="Horsepower"
          details={vehicleDetails.calculatedMDPFeatures.horsepower + " hp"}
          isSelected={selectedTitle === "Horsepower"}
          onSelect={() => handleSelect("Horsepower", horsepowerImg)}
        />
        <MdpButtonCard
          title="Torque"
          details={formattedTorque}
          isSelected={selectedTitle === "Torque"}
          onSelect={() => handleSelect("Torque", torqueImg)}
        />
      </div>
    </div>
  );
}

export default MdpPerformance;
