import "./MdpDrawers.scss";
import ExpandableDiv from "../expandableDiv/ExpandableDiv";
import MdpCompatibility from "../mdpCompatibility/MdpCompatibility";
import MdpTrimLevels from "../mdpTrimLevels/MdpTrimLevels";
import MdpDimensions from "../mdpDimensions/MdpDimensions";
import MdpFuelEconomy from "../mdpFuelEconomy/MdpFuelEconomy";
import MdpPerformance from "../mdpPerformance/MdpPerformance";
import MdpPricingTransparency from "../mdpPricingTransparency/MdpPricingTransparency";

function MdpDrawers({ vehicleDetails }) {
  return (
    <div>
      <ExpandableDiv title="Compatability" open={true}>
        <MdpCompatibility vehicleDetails={vehicleDetails} />
        <MdpTrimLevels vehicleDetails={vehicleDetails} />
      </ExpandableDiv>
      <ExpandableDiv title="Dimensions" open={true}>
        <MdpDimensions vehicleDetails={vehicleDetails} />
      </ExpandableDiv>
      <ExpandableDiv title="Fuel Economy" open={true}>
        <MdpFuelEconomy vehicleDetails={vehicleDetails} />
      </ExpandableDiv>
      <ExpandableDiv title="Pricing Transparency" open={true}>
        <MdpPricingTransparency vehicleDetails={vehicleDetails} />
      </ExpandableDiv>
      <ExpandableDiv title="Performance" open={true}>
        <MdpPerformance vehicleDetails={vehicleDetails} />
      </ExpandableDiv>
    </div>
  );
}

export default MdpDrawers;
