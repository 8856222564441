import "./DashboardSpeedDating.scss";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import Sidebar from "../sidebar/Sidebar";
import ButtonGradient from "../buttonGradient/ButtonGradient";
import MatchAnalysis from "../matchAnalysis/MatchAnalysis";
import speedDatingImage from "../../assets/images/speed-dating-dashboard.svg";
import noMatchesImg from "../../assets/images/noMatchesImage.svg";
import { BUTTON_CLICK_EVENT_TYPE } from "../../constants/userEvents";
import {
  trackUserEventAndUpdateAnonymousUserService,
  trackUserEventService,
} from "../../services/userEvent";
import Dashboard404 from "../dashboard404/Dashboard404";

function DashboardSpeedDating() {
  const { matchmaker, lastAction, isLoggedIn } = useAuthContext();
  console.log("Speedating matchmaker:", matchmaker);
  console.log("Speedating last action:", lastAction);

  if (!matchmaker.profileQuizMaeTrims && !matchmaker.profileQuiz) {
    return <Dashboard404 />;
  }
  const profileQuizMaeTrims = matchmaker.profileQuizMaeTrims;

  const carsToDate = profileQuizMaeTrims.filter((cars) => {
    return !cars.liked && !cars.disliked;
  });

  return (
    <>
      <div>{isLoggedIn ? <Sidebar /> : null}</div>
      <div className="dashboard-speed-dating">
        <h2 className="header">Match Analysis</h2>
        <h4 className="section-header">You’ve matched with</h4>
        <h1 className="subheader">{carsToDate.length} Vehicles</h1>
        <div className="dashboard-speed-dating__filler">
          {carsToDate.length === 0 ? (
            <div>
              <img
                className="dashboard-speed-dating__filler--image"
                style={{
                  height: "320px",
                }}
                src={noMatchesImg}
                alt=""
              />
            </div>
          ) : (
            <img
              className="dashboard-speed-dating__filler--image"
              src={speedDatingImage}
              alt=""
            />
          )}
        </div>
        <div className="dashboard-speed-dating__intro">
          <div className="dashboard__intro--description">
            {carsToDate.length === 0 ? (
              <p className="speed-dating__description">
                {profileQuizMaeTrims.length > 0
                  ? `You have no new matches. If you would like to consider more options, 
                  please adjust your vehicle preferences in the profile settings.`
                  : `You’ve got specific taste! Unfortunately there are no vehicles
                  that match what you’re looking for at the moment. We recommend
                  adjusting your profile, usually regarding engine type or budget.`}
                <p className="page404__support">
                  Need some support? Email our team at{" "}
                  <a href="mailto:mae@makingautoeasy.com">
                    mae@makingautoeasy.com
                  </a>
                  .
                </p>
              </p>
            ) : (
              <p className="speed-dating__description">
                {carsToDate.length < 6
                  ? "Let's speed date your options!"
                  : `Having ${carsToDate.length} potential suitors can be a bit overwhelming - Let’s speed-date some cars to narrow down your options.`}
              </p>
            )}
          </div>
          <div className="dashboard-speed-dating__intro--button">
            {carsToDate.length === 0 ? (
              <Link to={isLoggedIn ? "/my-profile" : "/matchmaker-quiz"}>
                {profileQuizMaeTrims.length ? (
                  <ButtonGradient style="bold" text="Profile Settings" />
                ) : (
                  <button
                    className="adjust-profile-btn"
                    onClick={() => {
                      trackUserEventService(
                        BUTTON_CLICK_EVENT_TYPE,
                        "Adjust Quiz Button Clicked"
                      );
                    }}
                  >
                    {isLoggedIn
                      ? `Change Profile Settings`
                      : `Adjust Your Quiz`}
                  </button>
                )}
              </Link>
            ) : (
              <Link to="/compare">
                <ButtonGradient
                  style="bold"
                  text="Meet your Matches"
                  onClick={() => {
                    trackUserEventAndUpdateAnonymousUserService(
                      BUTTON_CLICK_EVENT_TYPE,
                      "Start Speeddating Button Clicked",
                      { clickedStartSpeeddating: true }
                    );
                  }}
                />
              </Link>
            )}
          </div>
        </div>
        <MatchAnalysis />
      </div>
    </>
  );
}

export default DashboardSpeedDating;
