import "./DashboardGallery.scss";
import { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import TopRecommendations from "../topRecommendations/TopRecommendations";
import { MATCHMAKER_UPDATED } from "../../constants/lastActions";
import MatchesList from "../matchesList/MatchesList";

function DashboardGallery() {
  const { matchmaker, lastAction, updateMatchmaker } = useAuthContext();

  console.log("gallery page matchmaker", matchmaker);

  useEffect(() => {
    console.log("LAST ACTION:", lastAction);
    if (lastAction !== MATCHMAKER_UPDATED) {
      console.log("updating-matchmaker-");
      updateMatchmaker();
    }
  }, []);

  return (
    <div className="gallery">
      <div className="gallery__hero">
        <h3 className="gallery__hero--header">Your Matches</h3>
        <h1 className="gallery__hero--title">Your Matches</h1>
      </div>
      <TopRecommendations />
      <MatchesList selectionConstantlyEnabled={false} space={false}/>
    </div>
  );
}

export default DashboardGallery;
