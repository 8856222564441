import "./MatchAnalysis.scss";
import { useState, useEffect } from "react";
import { CalculateAnalysis } from "../../utils/calculateAnalysis";
import { useAuthContext } from "../../contexts/authContext";
import carTypeIcon from "../../assets/icons/match-analysis-type.svg";
import engineIcon from "../../assets/icons/match-analysis-engine.svg";
import driveTrainIcon from "../../assets/icons/match-analysis-drive-train.svg";
import cargoSpaceIcon from "../../assets/icons/match-analysis-cargo-space.svg";
import featuresIcon from "../../assets/icons/match-analysis-features.svg";
import priceRangeIcon from "../../assets/icons/match-analysis-price.svg";

function MatchAnalysis() {
  const { matchmaker } = useAuthContext();
  const [AnalysisInfo, setAnalysisInfo] = useState("");
  useEffect(() => {
    const newAnalysisInfo = CalculateAnalysis(matchmaker?.profileQuiz);
    setAnalysisInfo(newAnalysisInfo);
  }, [matchmaker]);

  return (
    <div className="match-analysis-wrapper">
      <div className="match-analysis">
        <h2 className="match-analysis__header">Build</h2>
        <div className="match-analysis__table">
          <div className="feature">
            <img className="feature__img" src={carTypeIcon} />
            <p className="feature__text">Type</p>
          </div>
          <div className="selection">
            <p className="selection__text">{AnalysisInfo?.Type}</p>
          </div>
        </div>
        <div className="match-analysis__table">
          <div className="feature">
            <img className="feature__img" src={engineIcon} />
            <p className="feature__text">ENGINE</p>
          </div>
          <div className="selection">
            <p className="selection__text">{AnalysisInfo?.Engine}</p>
          </div>
        </div>
        <div className="match-analysis__table">
          <div className="feature">
            <img className="feature__img" src={driveTrainIcon} />
            <p className="feature__text">DRIVE TRAIN</p>
          </div>
          <div className="selection">
            <p className="selection__text">
              {AnalysisInfo ? AnalysisInfo["Drive Train"] : ""}
            </p>
          </div>
        </div>
      </div>
      <div className="match-analysis">
        <h2 className="match-analysis__header">Traits</h2>
        <div className="match-analysis__table">
          <div className="feature">
            <img className="feature__img" src={cargoSpaceIcon} />
            <p className="feature__text">CARGO SPACE</p>
          </div>
          <div className="selection">
            <p className="selection__text">
              {AnalysisInfo ? AnalysisInfo["Cargo Space"] : ""}
            </p>
          </div>
        </div>
        <div className="match-analysis__table">
          <div className="feature">
            <img className="feature__img" src={featuresIcon} />
            <p className="feature__text">FEATURES</p>
          </div>
          <div className="selection">
            <p className="selection__text">{AnalysisInfo?.Features}</p>
          </div>
        </div>
        <div className="match-analysis__table">
          <div className="feature">
            <img className="feature__img" src={priceRangeIcon} />
            <p className="feature__text">PRICE RANGE</p>
          </div>
          <div className="selection">
            <p className="selection__text">
              {AnalysisInfo ? AnalysisInfo["Price Range"] : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MatchAnalysis;
