export const SPEEDATING_RESULTS_SAVED = "SPEEDATING_RESULTS_SAVED";
export const NEW_SPEEDATING_SWIPE = "NEW_SPEEDATING_SWIPE";
export const UPDATE_MATCHMAKER = "UPDATE_MATCHMAKER";
export const MATCHMAKER_UPDATED = "matchmaker-updated";
export const PROFILE_GENERATED = "profile-generated";
export const ANONYMOUS_PROFILE_GENERATED = "ANONYMOUS_PROFILE_GENERATED";
export const NAVIGATED_TO_MATCHES = "navigated to matches";
export const NAVIGATED_TO_SPEED_DATING = "navigated to speed dating";
export const NAVIGATED_TO_COMPARE_PAGE = "navigated to compare page";
export const USER_UPDATED = "user-updated";
export const PROFILE_UPDATED = "profile-updated";
export const NOT_AUTHENTICATED = "not-authenticated";
export const NO_LAST_ACTION = "";
