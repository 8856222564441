import "./comparisonChartCompatibilitySection.scss";
import EllipsePercentMatch from "../ellipsePercentMatch/EllipsePercentMatch";

function CompatibililtyMatchText({ boldText, normalText }) {
  return (
    <div className="cc-match__text">
      <div className="cc-match__text--row">
        <p className="cc-text-bold">{boldText}</p>{" "}
        <p className="cc-text-normal">{normalText}</p>
      </div>
    </div>
  );
}

export default function ComparisonChartCompatibilitySection({ vehicle }) {
  return (
    <>
      <div className="cc-liked-card-match">
        <div className="cc-liked-card-match__circle">
          <EllipsePercentMatch percentage={vehicle.compatibilityPercentage} />
          <div className="cc-liked-card-match-stamp">
            <p className="cc-liked-card-match-stamp__number">
              {vehicle.compatibilityPercentage}%
            </p>
          </div>
        </div>
        <CompatibililtyMatchText boldText="8/8" normalText="requirements" />
        <CompatibililtyMatchText
          boldText={`${vehicle.totalFeatures.length}/${vehicle.matchedFeatures.length}`}
          normalText="features"
        />
      </div>
    </>
  );
}
