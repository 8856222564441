import "./DashboardHome.scss";
import { Link } from "react-router-dom";
import dashboardImage from "../../assets/images/dashboard-icon.svg";
import dashboardLogo from "../../assets/logos/Mae-Core-Logo-Yellow.png";
import ButtonGradient from "../buttonGradient/ButtonGradient";
import ButtonPlain from "../buttonPlain/ButtonPlain";
import { useAuthContext } from "../../contexts/authContext";
import {
  trackUserEventAndUpdateAnonymousUserService,
  trackUserEventService,
} from "../../services/userEvent";
import { BUTTON_CLICK_EVENT_TYPE } from "../../constants/userEvents";

function DashboardHome() {
  const { isLoggedIn, matchmaker } = useAuthContext();

  return (
    <div className="dashboard">
      <div className="dashboard__intro">
        <img
          className="dashboard__intro--logo"
          src={dashboardLogo}
          alt="Mae Core Logo"
        />
        <h1 className="dashboard__intro--title">
          Find your <br /> 4-wheeled soulmate
        </h1>
        <p className="dashboard__intro--description">
          Answer a few questions to personalize your ideal car, meet all your
          matches & speed date to get to know them quickly, and build your own
          shortlist of "Liked" vehicles & get your top 6 recommendations.
        </p>
        <div className="dashboard__intro--buttons">
          {isLoggedIn &&
          "profileQuiz" in matchmaker &&
          matchmaker.profileQuiz.length > 0 ? (
            <Link to="matches">
              <ButtonGradient text="Go to Profile" />
            </Link>
          ) : (
            <Link to="matchmaker-quiz">
              <ButtonGradient
                text="Enter Matchmaker"
                onClick={() => {
                  trackUserEventAndUpdateAnonymousUserService(
                    BUTTON_CLICK_EVENT_TYPE,
                    "Enter Matchmaker Button Clicked",
                    { clickedEnterMatchmaker: true }
                  );
                }}
              />
            </Link>
          )}

          {isLoggedIn ? null : (
            <>
              <div className="divider">
                <p className="divider__text">Already have an account?</p>
              </div>
              <Link to="signin">
                <ButtonPlain
                  text="Sign In"
                  onClick={() => {
                    trackUserEventService(
                      BUTTON_CLICK_EVENT_TYPE,
                      "Sign In Button On Homepage Clicked"
                    );
                  }}
                />
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="dashboard__filler">
        <img
          className="dashboard__filler--image"
          src={dashboardImage}
          alt=""
        ></img>
      </div>
    </div>
  );
}

export default DashboardHome;
