import {
  BUDGET_QUIZ_INDEX,
  CAR_TYPE_QUIZ_INDEX,
  VEHICLE_POWER_QUIZ_INDEX,
  WEATHER_CONDITION_QUIZ_INDEX,
} from "../constants/quizIndex";

export function CalculateAnalysis(questionsResponse) {
  if (questionsResponse === undefined) return;
  const AnalysisObj = {
    Type: {
      Car: "Car",
      Truck: "Truck",
      "Small SUV": "SUV",
      "Medium SUV": "SUV",
      "Large SUV": "SUV",
      Minivan: "Minivan",
    },
    Transmission: "Automatic",
    Engine: {
      Gasoline: "Gasoline",
      Hybrid: "Hybrid",
      "Plug-In Hybrid": "Plug-In Hybrid",
      "Full electric": "Full Battery Electric Vehicle",
      Diesel: "Diesel",
    },
    "Drive Train": {
      "Fair Weather Zoomer":
        "Forward Wheel Drive, Rear Wheel Drive or All Wheel Drive",
      "It Snows Frequently": "All Wheel Drive",
      "Off-roading is my jam": "4 Wheel Drive",
    },
    "Cargo Space": {
      Car: "Medium",
      Truck: "Large",
      "Small SUV": "Small",
      "Medium SUV": "Medium",
      "Large SUV": "Large",
      Minivan: "Large",
    },
    "Fuel Efficiency": "City Zipper",
    "Price Range": "",
  };

  const formattedEngineString = questionsResponse[
    VEHICLE_POWER_QUIZ_INDEX
  ].selectedOptions
    .map((engineType, index) => {
      let temp = engineType;
      if (engineType === "PHEV") {
        temp = "Plug-In Hybrid";
      }

      if (engineType === "HEV") {
        temp = "Hybrid";
      }

      return index <
        questionsResponse[VEHICLE_POWER_QUIZ_INDEX].selectedOptions.length - 1
        ? `${temp}, `
        : temp;
    })
    .join("");

  return questionsResponse
    ? {
        Type: AnalysisObj["Type"][
          questionsResponse[CAR_TYPE_QUIZ_INDEX].selectedOptions[0]
        ],
        Transmission: AnalysisObj["Transmission"],
        Engine: formattedEngineString,
        "Drive Train":
          AnalysisObj["Drive Train"][
            questionsResponse[WEATHER_CONDITION_QUIZ_INDEX].selectedOptions[0]
          ],
        "Cargo Space":
          AnalysisObj["Cargo Space"][
            questionsResponse[CAR_TYPE_QUIZ_INDEX].selectedOptions[0]
          ],
        Features: "Best Trim Package Match",
        "Fuel Efficiency": AnalysisObj["Fuel Efficiency"],
        "Price Range": questionsResponse[BUDGET_QUIZ_INDEX].selectedOptions[0],
      }
    : {};
}
