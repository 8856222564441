// LoadingScreen.js
import React from "react";
import "./loadingScreen.css"; // Create a separate CSS file for styling

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingScreen;
