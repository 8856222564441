import "./ExpandableDiv.scss";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import expandableIcon from "../../assets/icons/mdp-expand-button.svg";
import closeIcon from "../../assets/icons/mdp-close-button.svg";

function ExpandableDiv({ title, children, open }) {
  const [isOpen, setIsOpen] = useState(open);

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
    },
    collapsed: {
      opacity: 0,
      height: 0,
    },
  };

  return (
    <div>
      <div className="expand-title">
        <p className="expand-title__text">{title}</p>
        <button
          className="expand-title__button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={isOpen ? expandableIcon : closeIcon} alt="" />
        </button>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
            transition={{
              duration: 0.8,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default ExpandableDiv;
