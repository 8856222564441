import "./FeatureRequirement.scss";
import { ReactComponent as RequirementPositive } from "../../assets/icons/mdp-requirements-yes.svg";
import { ReactComponent as RequirementNegative } from "../../assets/icons/mdp-requirements-no.svg";

export default function FeatureRequirementIcon({ requirementPresent }) {
  return (
    <div className="requirement-container">
      {requirementPresent ? <RequirementPositive /> : <RequirementNegative />}
    </div>
  );
}
